import './Drawer.scss'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Logo from '../../assets/images/Logo/Logo.png';
import { ROUTES } from '../../constants';
import DownloadModal from '../Modal';
import { useState } from 'react';

function DownloadDialog(props) {
    const { onClose, selectedValue, open } = props;
  
    const handleClose = () => {
      onClose(selectedValue);
    };
  
  
    return (
      <Dialog onClose={handleClose} open={open}  maxWidth="md">
  
        <DownloadModal/>
  
      </Dialog>
    );
  }
  
  DownloadDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string,
  };
const Drawer = ({show, click,}) => {
    const [helpopen, setHelpopen] = useState(false);
  const handleHelpClose = () => {
    setHelpopen(false);
  };
  const handleHelpOpen = () => {
    setHelpopen(true);
  };

    const drawerClass = ["drawer"]
    if (show) {
        drawerClass.push("show")
    }
    return (
        <div className={drawerClass.join(" ")}>
            <div className="logo" onClick={click}>
                <Link to={ROUTES.HOME}>
                    <img src={Logo} alt="myfaires" />
                </Link>
            </div>
            <div className="dismiss" onClick={click}>
                &times;
            </div>
            <ul className="links" >
                <li onClick={click}>
                    <Link to={ROUTES.HOME}>Home</Link>
                </li>
                <li >
                </li>
                <li onClick={click}>
                    <Link to={ROUTES.ABOUT}>About Us</Link>
                </li>
                <li onClick={click}>
                    <Link to={ROUTES.PRODUCT}>Our Products</Link>
                </li>

                <li onClick={click}>
                    <Link to={ROUTES.BLOG}>Blog</Link>
                </li>
                <button className='btn' onClick={handleHelpOpen}>
                    <Link to='#' onClick={handleHelpOpen}>
                    Download
                    </Link>
                </button>
            </ul>

            <DownloadDialog
        open={helpopen}
        onClose={handleHelpClose}
        >
        
        </DownloadDialog>
        </div>

        
    )
    
}

export default Drawer
