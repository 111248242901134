// @flow
import React from "react";
import BlogDescriptionView from "./BlogDescriptionView";

class BlogDescriptionController extends React.Component {
  static propTypes = {};

  render() {
    return <BlogDescriptionView {...this.props} />;
  }
}

export default BlogDescriptionController;
