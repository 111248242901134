// @flow
import React from "react";
import ProductView from "./ProductView";

class ProductController extends React.Component {
  static propTypes = {};

  render() {
    return <ProductView {...this.props} />;
  }
}

export default ProductController;
