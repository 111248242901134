// @flow
import React from "react";
import BlogView from "./BlogView";

class BlogController extends React.Component {
  static propTypes = {};

  render() {
    return <BlogView {...this.props} />;
  }
}

export default BlogController;
