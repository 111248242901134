// @flow
import React, { useEffect, useState } from "react";
import { Drawer, Leave, WebFooter, WebHeader, Product } from "../../components";
import Logo from '../../assets/images/Logo2.png'
import styled from 'styled-components'



const Privacy = () => {
    const [sideToggle, setSideToggle] = useState(false);

    return (
        <div className="product" style={{ backgroundColor: "#FFF8F8" }}>
            <WebHeader logo={Logo} hcolor="#FFABBC" bgColor="#FFF" btcolor="#fff" btnColor="#FFABBC" color='#190714' show={sideToggle} click={() => setSideToggle(!sideToggle)} />
            <Drawer show={sideToggle} click={() => setSideToggle(!sideToggle)} />


            <section style={{ filter: sideToggle === true ? 'blur(8px)' : 'none' }}>
            <Container dangerouslySetInnerHTML={{ __html: `<p><strong><span style="">Privacy Policy</span></strong></p>
<p><br/></p>
<p><span style="">Last Updated: 01/12/2023</span></p>
<p><br/></p>
<p><span style="">MyFairies Healthcare Limited (&quot;MyFairies,&quot; &quot;we,&quot; &quot;us,&quot; or &quot;our&quot;) operates the MyFairies Healthcare website and associated services (collectively referred to as the &quot;Service&quot;). This Privacy Policy outlines how we collect, use, and protect your personal information when you use our Service.</span></p>
<p><br/></p>
<p><strong><span style="">1. Information We Collect</span></strong></p>
<p><br/></p>
<p><span style="">Personal Information</span></p>
<ul>
    <li style="list-style-type:disc;font-size:10pt;">
        <p><strong><span style="">Registration Information:</span></strong><span style="">&nbsp;When you create an account, we may collect personal information such as your name, email address, date of birth, and gender.</span></p>
    </li>
    <li style="list-style-type:disc;font-size:10pt;">
        <p><strong><span style="">Menstrual Cycle Data:</span></strong><span style="">&nbsp;We may collect data related to your menstrual cycle, including cycle length, period start and end dates, symptoms, and other relevant health information.</span></p>
    </li>
    <li style="list-style-type:disc;font-size:10pt;">
        <p><strong><span style="">Communication Data:</span></strong><span style="">&nbsp;This includes any communication you send to us, such as messages to professionals or other users.</span></p>
    </li>
</ul>
<p><span style="">Usage Data</span></p>
<ul>
    <li style="list-style-type:disc;font-size:10pt;">
        <p><strong><span style="">Device Information:</span></strong><span style="">&nbsp;We automatically collect information about the device you use to access our Service, including your IP address, device type, operating system, and browser type.</span></p>
    </li>
    <li style="list-style-type:disc;font-size:10pt;">
        <p><strong><span style="">Usage Patterns:</span></strong><span style="">&nbsp;We collect information about how you interact with our Service, such as the pages you visit, the features you use, and the time spent on each page.</span></p>
    </li>
</ul>
<p><span style="">Cookies and Tracking Technologies</span></p>
<ul>
    <li style="list-style-type:disc;font-size:10pt;">
        <p><span style="">We may use cookies and similar tracking technologies to enhance your experience on our Service, personalize content, and analyze usage patterns. You can manage your cookie preferences through your browser settings.</span></p>
    </li>
</ul>
<p><br/></p>
<p><strong><span style="">2. Use of Information</span></strong></p>
<p><br/></p>
<p><span style="">We use the information we collect for various purposes, including:</span></p>
<ul>
    <li style="list-style-type:disc;font-size:10pt;">
        <p><span style="">Providing and maintaining the Service</span></p>
    </li>
    <li style="list-style-type:disc;font-size:10pt;">
        <p><span style="">Personalizing your experience</span></p>
    </li>
    <li style="list-style-type:disc;font-size:10pt;">
        <p><span style="">Analyzing usage trends and improving our Service</span></p>
    </li>
    <li style="list-style-type:disc;font-size:10pt;">
        <p><span style="">Communicating with you, including responding to inquiries and providing customer support</span></p>
    </li>
    <li style="list-style-type:disc;font-size:10pt;">
        <p><span style="">Facilitating interactions between users and healthcare professionals</span></p>
    </li>
    <li style="list-style-type:disc;font-size:10pt;">
        <p><span style="">Complying with legal obligations</span></p>
    </li>
</ul>
<p><br/></p>
<p><strong><span style="">3. Data Sharing and Disclosure</span></strong></p>
<p><br/></p>
<p><span style="">We may share your personal information in the following circumstances:</span></p>
<ul>
    <li style="list-style-type:disc;font-size:10pt;">
        <p><span style="">With your consent</span></p>
    </li>
    <li style="list-style-type:disc;font-size:10pt;">
        <p><span style="">With healthcare professionals or other users, as necessary to provide our services</span></p>
    </li>
    <li style="list-style-type:disc;font-size:10pt;">
        <p><span style="">With service providers who assist us in operating our Service</span></p>
    </li>
    <li style="list-style-type:disc;font-size:10pt;">
        <p><span style="">To comply with legal obligations or protect our rights</span></p>
    </li>
</ul>
<p><br/></p>
<p><strong><span style="">4. Data Security</span></strong></p>
<p><br/></p>
<p><span style="">We take appropriate measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure.</span></p>
<p><br/></p>
<p><strong><span style="">5. Your Rights</span></strong></p>
<p><br/></p>
<p><span style="">You have certain rights regarding your personal information, including the right to access, correct, or delete your data. You may also have the right to restrict or object to certain processing activities. Please contact us if you wish to exercise any of these rights.</span></p>
<p><br/></p>
<p><strong><span style="">6. Changes to this Privacy Policy</span></strong></p>
<p><br/></p>
<p><span style="">We may update this Privacy Policy from time to time. Any changes will be posted on our website at&nbsp;</span><a href="https://www.myfairies.co.uk/"><u><span style="color:#467886;">www.myfairies.co.uk</span></u></a><span style="">, with the effective date indicated at the top. We encourage you to review this Privacy Policy periodically for any updates.</span></p>
<p><br/></p>
<p><strong><span style="">7. Contact Us</span></strong></p>
<p><br/></p>
<p><span style="">If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at hello@myfairies.co.uk or write to us at:</span></p>
<p><span style="">MyFairies Healthcare Limited</span><span style=""><br/></span><span style="">Cardiff Bay Business Centre</span><span style=""><br/></span><span style="">Lewis Road</span><span style=""><br/></span><span style="">Cardiff, Wales</span><span style=""><br/></span><span style="">CF24 5BS</span></p>
<p><br/></p>
<p><span style="">By using our Service, you consent to the collection and use of your information as described in this Privacy Policy.</span></p>
           ` }}>
             </Container>
                <WebFooter />
            </section>


        </div>
    )
}
export default Privacy
const Container = styled.div`
padding:50px 50px;
font-family:Inter;
font-size:18px;
color:#000;
p{
    font-family:Inter;
    font-size:18px;
      
}
ul {
    margin 5px;
    li{
        margin 5px;
    }
}
`;