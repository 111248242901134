import { StyleSheet } from "aphrodite";


export default StyleSheet.create({
    blogCard:{
        width: "100%",
        height: "auto",
        background: "rgba(255, 255, 255, 0.2)",
        padding: "45px 0px",
    },
    img:{
        width: "100%"
    },
    blogCardBox:{
        fontWeight: "400",
        fontSize: "22px",
        lineHeight: "27px",
        color: "#ABA5A6",
        padding: "44px 100px"
    },
    p:{
        marginBottom: "20px"
    },
    card:{
        padding: "0 100px"
    },
    h3:{
        fontWeight: "600",
        fontSize: "48px",
        lineHeight: "93.5%",
        color: "#190714",
        marginBottom: "25px",
    },
    girlImg:{
        width: "100%",
    },
});