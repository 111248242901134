// @flow
import React from "react";

import CardDView from "./CardDView";

export default class CardDController extends React.Component {
 

  render() {
    return (
      <CardDView {...this.props} />
    );
  }
}
