// @flow
import React from "react";

import CardEView from "./CardEView";

export default class CardEController extends React.Component {
 

  render() {
    return (
      <CardEView {...this.props} />
    );
  }
}
