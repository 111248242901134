import React from 'react'
import './Styles.scss'

const index = (props) => {
  return (
    <div className='leave' style={{width: props.width ? props.width :'846px'}}>
        <form>
            <input type="email" name="" id="" placeholder='Enter email' style={{borderRadius:props.br? props.br :'5px',height:props.height?props.height: '75px' }}/>
            <button className='btn' type='submit' style={{borderRadius:props.br? props.br :'5px',height:props.height?props.height: '75px' }}>
              {
                props.logo?
              <img src={props.logo} alt="..." style={{margin:'0 20px 0 -50px'}}/>:
              ""
              }
              <span>{props.text ? props.text :'Subscribe'}</span>
              </button>
        </form>
    </div>
  )
}

export default index