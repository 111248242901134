import { StyleSheet } from "aphrodite";



export default StyleSheet.create({
    blogCard:{
        width: "600px",
        height: "585px",
        borderRadius: "50px",
    },
    blogCardBox:{
        width: "100%",
        height: "400px",
        position: "relative"
    },
    blogimg :{
        position: "relative",
        width: "100%"
    },
    myflairlogo:{
        position: "absolute",
        top: "52px",
        left: "48px",
        zIndex: "1",
    },
    blogCardCon: {
        width: "100%",
        height: "185px",
        background: "rgba(255, 255, 255, 0.3)",
        borderRadius: "0px 0px 50px 50px",
        boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.1)",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "end",
        padding: "27px 47px",
    },
    textA: {
        fontWeight: "600",
        fontSize: "16px",
        // lineHeight: "93.5%",
        color: "#FFABBC",
    },
    textB:{
        fontWeight: "600",
        fontSize: "25px",
        // lineHeight: "93.5%",
        color: "#190714",
        maxWidth: "273px",
        paddingBottom: "15px",
    },
    iconBox:{
        display: "flex",
    },
    icon:{
        marginRight: "22px",
        display: "flex",
        alignItems: "center",
    },
    iconText:{
        fontWeight: "500",
        fontSize: "12px",
        lineHeight: "16px",
        color: "#1D1E2C",
        marginLeft: "5px",
    },
    btn:{
        background: "#FFABBC",
        borderRadius: "5px",
        fontWeight: "600",
        fontSize: "15px",
        lineHeight: "17px",
        color: "#FFFFFF",
        border: "none",
        outline: "none",
        maxWidth: "173.4px",
        padding: "19px",
        width: "173.4px"
    }

});