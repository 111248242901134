import React from 'react'
import './Styles.scss'
import BlackAppStore from  '../../assets/images/BlackAppStore.png';
import BlackPlayStore from  '../../assets/images/BlackPlayStore.png';
import Mobile from  '../../assets/images/Mobile.png';
import {APP_LINK,PLAY_LINK} from "../../constants"

const index= () => {
  return (
    <div className='firstSection'>
        <div className="partA">
            <h1 className='head'>
            Know yourself better, even your periods.
            </h1>
            <p className="subhead">
            Get empowered when you know the stages of your menstrual cycle and your body reactions during these stages.
            </p>
            <div className='btnCon'>
              <p className='btn'><a target='_blank' href={PLAY_LINK}><span><img src={BlackPlayStore} alt="" /></span></a></p>
              <p className='btn'><a target='_blank' href={PLAY_LINK}><span><img src={BlackAppStore} alt=""/></span></a></p>
            </div>
        </div>
        <div className="partB">
            <img src={Mobile} alt="..." />
        </div>
        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path fill="#000" fill-opacity="1" d="M0,224L60,234.7C120,245,240,267,360,250.7C480,235,600,181,720,160C840,139,960,149,1080,170.7C1200,192,1320,224,1380,240L1440,256L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path>
        </svg> */}
       
    </div>
    
  )
}

export default index
