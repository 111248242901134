// @flow
import React from "react";

import CardAView from "./CardAView";

export default class CardAController extends React.Component {
 

  render() {
    return (
      <CardAView {...this.props} />
    );
  }
}
