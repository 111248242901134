// @flow
import React, { useEffect, useState } from "react";
import { Drawer, Leave, WebFooter, WebHeader, Product } from "../../components";
import Logo from '../../assets/images/Logo2.png'
import styled from 'styled-components'
import { useParams, useHistory } from 'react-router-dom';
import { allProducts } from "../products";
import { Radio,Button,Modal } from 'antd';
import {devices} from '../../styles/helper'
import Checkout from "./checkout";



const ProductSingle = (props) => {
    const { id } = useParams();
    const [sideToggle, setSideToggle] = useState(false);
    const [mainImage, setMainImage] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const history = useHistory();
    const [isModalOpen, setIsModalOpen] = useState(false);
    useEffect(() => {
        const filteredProduct = allProducts.filter(pro => pro.slug == id)
        if (filteredProduct.length > 0) {
            setSelectedProduct(filteredProduct[0])
            setMainImage(filteredProduct[0].image[0])
        } else {
            history.push("/products")
        }
    }, [id])
    const showModal = () => {
        setIsModalOpen(true);
      };
    return (
        <div className="product" style={{ backgroundColor: "#FFF8F8" }}>
            <WebHeader logo={Logo} hcolor="#FFABBC" bgColor="#FFF" btcolor="#fff" btnColor="#FFABBC" color='#190714' show={sideToggle} click={() => setSideToggle(!sideToggle)} />
            <Drawer show={sideToggle} click={() => setSideToggle(!sideToggle)} />


            <section style={{ filter: sideToggle === true ? 'blur(8px)' : 'none' }}>
            <Modal width={840} onCancel={()=>setIsModalOpen(false)} title="Checkout" open={isModalOpen} footer={null}>
        <Checkout product={selectedProduct} quantity={1} setIsModalOpen={setIsModalOpen} />
      </Modal>
            <Container>{
                    
                    selectedProduct && (
                        <Flex>
                            <ImageSection>
                                <Image width={340} height={"auto"} alt="" src={`/product/${mainImage}`} />
                                <OtherImage>
                                    {
                                        selectedProduct.image.map(v=><Image width={72} height={"auto"} alt="" src={`/product/${v}`} />)
                                    }
                                    
                                </OtherImage>

                            </ImageSection> 
                            <ProductSection>
                                <Title>{selectedProduct.name}</Title>
                                <Category>{selectedProduct.category}</Category>
                                <hr />
                                <Price>£{selectedProduct.price}</Price>
                                <Description>{selectedProduct.description}</Description>
                                <AvailableSize>
                                    <p>Available Sizes</p>
                                <Rad >
                               {
                                selectedProduct.sizes.map((v,idx)=>(
                                    <Radio.Button key={idx}  value={v}>{v}</Radio.Button>
                                ))
                               }     
      
    </Rad>
    </AvailableSize>
    <BuyNow onClick={()=>showModal()}>Buy Now</BuyNow>
                            </ProductSection>
                        </Flex>
                    )
                }

                </Container>
                <WebFooter />
            </section>


        </div>
    )
}
export default ProductSingle
const Container = styled.div`
padding:20px 20px;
`;
const BuyNow = styled(Button)`
width:100%;
font-family: 'Aristotelica Display Trial';
font-size: 16px;
font-weight: 600;
background-color: #FFABBC!important;
    color: #FFFFFF;!important;
    max-width:502px;
`;
const AvailableSize = styled.div`
display:flex;
gap:10px;
align-items:center;
p{
    font-family: 'Aristotelica Display Trial';
font-size: 18px;
font-weight: 600;
margin:20px 0px;
}
`;
const Flex = styled.div`
  display:flex;
  flex-direction: column;
  @media only screen and ${devices.md} {
    flex-direction: row;
  }
`;
const Rad = styled(Radio.Group)`
  display:flex;
  gap:10px;
  label{
    background-color: #EAEAEA;
    border-radius:0px!important;
    color:#190714;
    font-family: 'Aristotelica Display Trial';
font-size: 12px;
font-weight: 600;

  }
  .ant-radio-button-wrapper-checked{
    background-color: #FFABBC!important;
    color: #FFFFFF;!important;
  }
`;
const ImageSection = styled.div`
display:flex;
flex-direction:column;
flex:1;
justify-content:center;
align-items:center;
`
const Image = styled.img`
margin-bottom:10px;
cursor:pointer;
`
const OtherImage = styled.div`
display:flex;
gap:5px;
`
const ProductSection = styled.div`
flex:1;

`
const Title = styled.p`
font-family: 'Aristotelica Display Trial';
font-size: 40px;
font-weight: 600;
color: #1D1E2C;

`
const Category = styled.p`
font-family: 'Aristotelica Display Trial';
font-size: 14px;
font-weight: 600;
color: #979797;
`
const Price = styled.p`
font-family: Quicksand;
font-size: 35px;
font-weight: 700;
color: #1D1E2C;

`
const Description = styled.p`
font-family: Inter;
font-size: 16px;
font-weight: 400;
color: #1D1E2C;
max-width:502px;
`