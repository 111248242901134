// @flow
import React, { useState } from "react";
import "./HomeStyles.scss";
import { WebHeader, WebFooter, Drawer, CardB, CardC, CardE, CardA,FirstSection, SecondSection, Leave } from "../../components";
export default function HomeView() {
  const [sideToggle, setSideToggle] = useState(false);
  const [modalShow,setModalShow] = useState(false)
  return (
    <div className="homepage">
    <WebHeader show={sideToggle} click={() => setSideToggle(!sideToggle)}/>
      <Drawer show={sideToggle} click={() => setSideToggle(!sideToggle)}/>
      <section style={{filter:sideToggle===true? 'blur(8px)':'none'}}>
        <FirstSection/>
        <SecondSection/>
        <div className="cardC">
        <CardC/>
        </div>
        <div className="cardB">
         <CardB/>
        </div>
       <CardE/>
       <div className="cardA">
        <CardA/>
        </div>
        <Leave
        txt1="Leaving no"
        txt1b="behind."
        txt2="woman"
        txt3="We believe in equal access. We try to make MyFairies available to everyone. Join the women on this platform."
        />
      <WebFooter />
      </section>
    </div>
  );
}
