// @flow
import React, { useState } from "react";
import "./AboutStyles.scss";
import appstore from "../../assets/images/appstore.png"
import Connect from "../../assets/images/Connect.png"
import img513 from "../../assets/images/img513.png"
import playstore from "../../assets/images/playstore.png"
import Phone from "../../assets/images/Phone.png"
import Logo from '../../assets/images/Logo2.png'

import { WebHeader, WebFooter, Drawer,  Leave,Care} from "../../components";
import { APP_LINK, PLAY_LINK } from "../../constants";
export default function AboutView(props) {
  const [sideToggle, setSideToggle] = useState(false);
  return (
    <div className="about" >
      <WebHeader logo={Logo} hcolor="#FFABBC" bgColor="#FFF7F8" btcolor="#fff" btnColor="#FFABBC" color='#190714' show={sideToggle} click={() => setSideToggle(!sideToggle)} />
      <Drawer show={sideToggle} click={() => setSideToggle(!sideToggle)}/>
       
      <section style={{filter:sideToggle===true? 'blur(8px)':'none'}}>
      <div className="section1">
          <h1 className="head">Helping <span className="diff">100,000+ girls & women</span> in the UK understand their periods better.</h1>
          <p className="subhead">By giving access to expert information, knowledge, and support, we encourage our users to better understand how physiology affects their wellbeing.</p>
          <div className="flex">
            <div>
              <h1 className="title">1,000+</h1>
              <p className="subtitle">Downloads</p>
            </div>

            <div>
              <h1 className="title">500+</h1>
              <p className="subtitle">active users</p>
            </div>

          </div>
        </div>
        <div className="section2">
          <div className="card">
            <div>
            <p className="head">Why this <span className="diff" style={{fontFamily:"Aristotelica Display Trial"}}>MyFairies?</span></p>
            <p className="subhead">We support every woman who menstruates in their entire reproductive lives, with personalised cycle predictions, virtual health assistants, insights on general health, fertility and pregnancy.We work with a large team of medical doctors, therapists, social workers and lawyers to provide health and non-health related service for you.</p>
            <div className='btnCon'>
              <img src={img513} alt="" className="img" />
              <a href={PLAY_LINK} className='btn'>Playstore<span style={{marginLeft:5}}><img src={playstore} alt="" /></span></a>
              <a href={APP_LINK} style={{marginLeft:5}} className='btn'>App Store<span><img src={appstore} alt=""/></span></a>
            </div>
            </div>
            <div className="imgDiv">
              <img src={Phone} alt="" />
            </div>
          </div>
          <Care/>

          <div className="connect">
            <div className="w1240">
            <div className="partA">
              <h1 className="head">Connect with other women around you.</h1>
              <p className="subhead">Let’s connect you with other women that around you. Building the community for all women to connect and interact.</p>
              <button type="submit">
                <span>Start Connecting Now!</span>
              </button>
            </div>
            <div className="partB">
              <img src={Connect} alt="" />
            </div>
          </div>
            </div>
        </div>

        <Leave
        txt1="Leaving no"
        txt1b="behind."
        txt2="woman"
        txt3="We believe in equal access. We try to make MyFairies available to everyone. Join the women on this platform."
        />

      <WebFooter />
      </section>
    
      
    </div>
  );
}
