// @flow
import React, { useState,useEffect } from "react";
import { Drawer, WebFooter,WebHeader } from "../../components";
import "./BlogDescriptionStyles.scss";
import WomenImg from '../../assets/images/WomenImg.png'
import girl from '../../assets/images/girl.png'
import Logo from '../../assets/images/Logo2.png'
import Pix1 from '../../assets/images/Pix1.png'
import Time from '../../assets/images/Time.png'
import { allBlog } from "../blogData";
import { useParams,useHistory } from "react-router-dom";
import styled from 'styled-components'
export default function BlogDescriptionView() {
  const { id } = useParams();
  const [sideToggle, setSideToggle] = useState(false);
  const [mainBlog, setMainBlog] = useState();
  const history = useHistory();
  useEffect(() => {
      const filteredProduct = allBlog.filter(pro => pro.slug == id)
      if (filteredProduct.length > 0) {
        setMainBlog(filteredProduct[0])
      } else {
          history.push("/blog")
      }
  }, [id])
  return (
    <div className="blogdescription">
      <WebHeader logo={Logo} hcolor="#FFABBC" bgColor="#FFF" btcolor="#fff" btnColor="#FFABBC" color='#190714' show={sideToggle} click={() => setSideToggle(!sideToggle)} />
      <Drawer show={sideToggle} click={() => setSideToggle(!sideToggle)}/> 
      
      <section style={{filter:sideToggle===true? 'blur(8px)':'none'}}>
        <Container>
          
        
        {
          mainBlog&&(
            <>
            <Image src={mainBlog.image} />
            <div className="blog-details" dangerouslySetInnerHTML={{ __html: mainBlog.details }} />
            </>
          )
        }
      </Container>
      </section>
      <WebFooter />
    </div>
  );
}
const Container=styled.div`
display:flex;
flex-direction:column;
`
const Image=styled.img`
padding:0px 50px;
`