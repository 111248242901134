// @flow
import React from "react";
import {css} from "aphrodite"
import styles from "./CardCStyles"
import samsung from "../../assets/images/samsung.png"

export default function CardCView() {

/*  const topContainer = useRef();

  useEffect(() => {
    // To make sure page starts from the top
    topContainer.current.scrollIntoView({ block: "end", behavior: "smooth" });
  }, []);
 */
  return (
    <div className={css(styles.blogCard)}>
      <div className={css(styles.blogCardBox)}>
        <div className={css(styles.head)}>Speak with a 
          <span className={css(styles.headSpan)}> doctor</span> or <span className={css(styles.headSpan)}>therapist</span></div>
        <p className={css(styles.textA)}>Have you got questions or fears? Did you notice 
        something strange? Then you need to speak with our experts.</p>
        <div>
          <button className={css(styles.btn)}>Book an Appointment</button>
        </div>
      </div>
      <div className={css(styles.samsung)}>
        <img src={samsung} alt=""/>
      </div>
    </div>
  );
}
