import React from 'react'
import './Styles.scss'
import { Link } from 'react-router-dom'

const index = (props) => {
  const product=props.data
  return (
    <div className='product-single'>
      <img alt={product.name} src={`/product/${product.image[(Math.floor(Math.random() * product.image.length))]}`} />
      <p className='category'>{product.category}<span>£{product.price}</span></p>
      <p className='name'>{product.name}</p>
      <p className='description'>{product.description.substring(0,99)}...</p>
      <Link to={'/product/'+product.slug} className='link' >View More</Link>
        
    </div>
  )
}

export default index