import { StyleSheet } from "aphrodite";


export default StyleSheet.create({
    blogCard:{
        width: "1240px",
        height: "576px",
        background: "rgba(255, 255, 255, 0.2)",
        boxShadow: "0px 15px 70px rgba(0, 0, 0, 0.1)",
        borderRadius: "50px",
        padding: "45px 0px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center"

    },
    blogCardBox:{
       maxWidth: "846px"
    },
    head:{
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "60px",
        lineHeight: "78px",
        color: "#190714",
        // maxWidth: "601px",
        margin: "auto",
    },
    headSpan:{
        color: "#FFABBC",
    },
    textA: {
        fontWeight: "400",
        fontSize: "20px",
        lineHeight: "24px",
        color: "#190714",
        maxWidth: "593px",
        margin: "21px auto 58px auto",
    },
    inputBox:{
        maxWidth: "720px",
        height: "75px",
        display: "flex",
        alignItems: "center"
    },
    input:{
        background: "#FFFFFF",
        boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.1)",
        borderRadius: "5px",
        height: "100%",
        width: "calc(100% - 213px)",
        padding: "30px 0 29px 40px",
        fontWeight: "400",
        fontSize: "20px",
        lineHeight: "22px",           
        color: "rgba(196, 196, 196, 0.5)",
        ':focus': {
            '::-webkit-input-placeholder': {
                fontWeight: "400",
                fontSize: "20px",
                lineHeight: "22px",           
                color: "rgba(196, 196, 196, 0.5)",
            }
          }

    },
    btn:{
        background: "#FFABBC",
        borderRadius: "5px",
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "26px",
        color: "#FFFFFF",
        border: "none",
        outline: "none",
        maxWidth: "213px",
        padding: "26px 32px 30px 33px",
        width: "213px",
        height: "100%"
    }
});