// @flow
import React from "react";
import {css} from "aphrodite"
import styles from "./CardBStyles"
import woman from "../../assets/images/woman.png"
import Lady from "../../assets/images/Lady.png"

export default function CardBView(props) {

/*  const topContainer = useRef();

  useEffect(() => {
    // To make sure page starts from the top
    topContainer.current.scrollIntoView({ block: "end", behavior: "smooth" });
  }, []);
 */
  return (
    <div className={css(styles.blogCard)}>
      <div className={css(styles.woman)}>
        <img src={woman} alt='' className={css(styles.womanImg)}/>
        <img src={Lady} alt='' className={css(styles.lady)}/>
      </div>
      <div className={css(styles.blogCardBox)}>
        <div className={css(styles.head)}>Know when to hit<span className={css(styles.headSpan)}> without fear!</span> 😉</div>
        <p className={css(styles.textA)}>You can choose to avoid pregnancy or 
          catch one when you know the best times your body is prepared for both.</p>
        <div>
          <button className={css(styles.btn)}>Get the app Now!</button>
        </div>
      </div>
    </div>
  );
}
