import { StyleSheet } from "aphrodite";


export default StyleSheet.create({
    blogCard:{
        width: "1240px",
        height: "576px",
        background: "rgba(255, 255, 255, 0.2)",
        boxShadow: "0px 15px 70px rgba(0, 0, 0, 0.1)",
        borderRadius: "50px",
        padding: "45px 0px",
        position:'relative',
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        '@media(max-width:1285px)':{
          width:'90%',
          margin:'10px auto',
          '@media(max-width:1220px)':{
            fontSize:'60px',
            flexDirection:'column-reverse',
            height:'unset',
            minHeight:'576px',
            paddingBottom:'150px'
          }
        }
        
    },
    head:{
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "75px",
        fontFamily:'Aristotelica Display Trial',
        lineHeight: "78px",
        color: "#190714",
        maxWidth: "601px",
        margin: "auto 80px",
        '@media(max-width:1285px)':{
            margin:'20px auto',
            fontSize:'60px',
            width:'500px',
            lineHeight:'60px',
            '@media(max-width:720px)':{
               width:'296px',
               margin:'10px auto',
               fontSize:'36px',
               lineHeight:'40px',
               '@media(max-width:406px)':{
                width:'266px',
                fontSize:'29px',
                lineHeight:'30px',
                
             }
              }
          }
    },
    headSpan:{
        color: "#FFABBC",
    },

    textA: {
        fontWeight: "400",
        fontSize: "20px",
        fontFamily:'Inter',
        lineHeight: "24px",
        color: "#190714",
        maxWidth: "473px",
        margin: "30px 80px 50px 80px",
        '@media(max-width:1285px)':{
            fontSize:'18px',
            margin:'auto',
            '@media(max-width:720px)':{
                width:'276px',
                margin:'20px auto',
                fontSize:'12px',
                lineHeight:'15px'
               }
          }
    },
    btn:{
        background: "#FFABBC",
        borderRadius: "5px",
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "26px",
        color: "#FFFFFF",
        border: "none",
        outline: "none",
        marginLeft:'80px',
        maxWidth: "257px",
        padding: "26px 32px 30px 33px",
        width: "257px",
        '@media(max-width:1285px)':{
            margin:'10px 80px',
            '@media(max-width:1220px)':{
                position:'absolute',
                bottom:'30px',
                right:'200px',
                '@media(max-width:930px)':{
                    right:180,
                    width:163,
                    fontSize:13,
                    padding:'20px 0px',
                    bottom:'50px',
                    '@media(max-width:750px)':{
                        right:'120px',
                        '@media(max-width:640px)':{
                            right:'80px',
                            '@media(max-width:558px)':{
                               right:'40px',
                               '@media(max-width:450px)':{
                                right:'20px',
                                '@media(max-width:400px)':{
                                    padding:'10px 0',
                                    marginTop:'-20px'
                                }

                              }
                              }
                          }
                      }
                  }
              }
          }
    },
    woman:{
        margin:'10px 20px',
        '@media(max-width:1220px)':{
            width:'80%',
            '@media(max-width:508px)':{
                width:'unset'
                
             }
        }
    },
    womanImg:{
        
        '@media(max-width:1220px)':{
            display:'none'
          }
    
    },
    lady:{
        display:'none',
        '@media(max-width:1220px)':{
            display:'flex',
            width: '100%',
            
          }
    
    },
});