// @flow
import React, { useState } from "react";
import { Drawer, Leave, WebFooter,WebHeader,Product } from "../../components";
import { allProducts} from "../products";
import "./ProductStyles.scss";


import Logo from '../../assets/images/Logo2.png'
import styled from 'styled-components'
export default function ProductView() {
  const [sideToggle, setSideToggle] = useState(false);
  return (
    <div className="product" style={{backgroundColor:"#FFF8F8"}}>
    <WebHeader logo={Logo} hcolor="#FFABBC"  bgColor="#FFF" btcolor="#fff" btnColor="#FFABBC" color='#190714' show={sideToggle} click={() => setSideToggle(!sideToggle)} />
    <Drawer show={sideToggle} click={() => setSideToggle(!sideToggle)}/> 
        
      <section style={{filter:sideToggle===true? 'blur(8px)':'none'}}>
      <Container>
      {
          allProducts.map((v,i)=>
            <Product data={v} key={i} />
          )
        }
      </Container>
        
       
      <WebFooter />
      </section>

      
    </div>
  );
}

const Container = styled.div`
padding:30px 30px;
display:flex;
flex-wrap:wrap;
gap:15px;
`;