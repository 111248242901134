import React from 'react'
import './Styles.scss'
import Logo  from '../../assets/images/Logo2/Logo2.png';
import Clock from '../../assets/images/Clock/Clock.png';
import Like from '../../assets/images/Like/Like.png';
import Flag from '../../assets/images/Flag/Flag.png';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants';

const index = (props) => {
    const style = {
        background: `url(${props.img}) no-repeat`,
        backgroundColor: `${props.bg}`,
        backgroundRepeat: "no-repeat",
        backgroundPosition:'50%  80%',
        backgroundSize: "cover",
    }
  return (
    <div className='period'>
        <div className="flex_1" style={style}>
        <img src={Logo} alt="" className='logo'/>
        </div>
        <div className="flex_2">
            <div className="flex" style={{width:props.txtWidth ? props.txtWidth : "273px"}}>
                <p className="support_text">
                {props.title}
                </p>
                <p className="bias_text" >
                {props.subtitle}
                </p>
            </div>
              <Link className='btn' to={props.url}>
              <span>Read More</span>
              </Link>
        </div>
    </div>
  )
}

//Imported in the homeview

export default index