// @flow
import  './WebHeaderStyles.scss'
import React,{useState} from "react";
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Logo from '../../assets/images/Logo/Logo.png'
import { Squash as Hamburger } from 'hamburger-react';
import { Link } from "react-router-dom";
import DownloadModal from '../Modal';
import {ROUTES }from '../../constants';


function DownloadDialog(props) {
    const { onClose, selectedValue, open } = props;
  
    const handleClose = () => {
      onClose(selectedValue);
    };
  
  
    return (
      <Dialog onClose={handleClose} open={open}  maxWidth="md">
  
        <DownloadModal/>
  
      </Dialog>
    );
  }
  
  DownloadDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string,
  };
const Header = function WebHeaderView({click,show, ...props}){
    const [helpopen, setHelpopen] = useState(false);
  const handleHelpClose = () => {
    setHelpopen(false);
  };
  const handleHelpOpen = () => {
    setHelpopen(true);
  };

  return (
    <div className='header' style={{backgroundColor: props.bgColor? props.bgColor: '#FFABBC'}}>
                {/* logo */}
                 <div className="logo">  
                    <Link to= '/' className='logo'>
                        <img src={props.logo? props.logo: Logo} alt="myflair" />
                    </Link>
                </div>

                {/* links */}
                <ul className="links">
                    <li >
                        <Link to={ROUTES.ABOUT} style={{color:props.color? props.color: '#fff'}}>
                                 About Us
                        </Link>
                    </li>
                    <li>
                        <Link to={ROUTES.PRODUCT} style={{color:props.color? props.color: '#fff'}}>
                        Our Products
                        </Link>
                    </li>


                    <li>
                        <Link to={ROUTES.BLOG} style={{color:props.color? props.color: '#fff'}}>
                            Blog
                        </Link>
                    </li>

                    <Link style={{color:props.btcolor ? props.btcolor : "#190714"}}>
                        <li className='download__button' onClick={handleHelpOpen} style={{background:props.btnColor? props.btnColor: '#fff'}}>
                                Download
                        </li>
                    </Link>
                    
                    
                </ul>
                {/* Harmburger menu */}
                <div className='hamburger__menu' onClick={click} style={{color: props.hcolor? props.hcolor: '#fff'}}>
                    <Hamburger toggled={show} toggle={click} />
                </div>

                <DownloadDialog
        open={helpopen}
        onClose={handleHelpClose}
        >
        
        </DownloadDialog>
        </div>

    
  );
}
export default Header