import { StyleSheet } from "aphrodite";


export default StyleSheet.create({
    blogCard:{
        width: "1240px",
        minHeight: "576px",
        margin:"10px auto",
        background: "rgba(255, 255, 255, 0.2)",
        borderRadius: "50px",
        padding: "45px 0px",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        position: "relative",
        '@media(max-width:1260px)':{
            width:'100%',
            margin:'50px auto ',
            '@media(max-width:950px)':{
                flexDirection:'column'
            }
        }

    },
    blogCardBox:{
        position: "absolute",
        width: "650px",
        left: "-50px",
        '@media(max-width:1465px)':{
            left: 0,
            '@media(max-width:1285px)':{
                left: '50px',
                '@media(max-width:950px)':{
                    position:'sticky',
                    margin:'auto',
                    // textAlign:'center',
                    '@media(max-width:600px)':{
                       width:'314px',

                    }
                }
            }
        }
    },
    samsung:{
        position: "absolute",
        bottom: "0px",
        right: "-150px",
        overflow: "hidden",
        '@media(max-width:1465px)':{
            right: '-100px',
            '@media(max-width:1405px)':{
                transform :'scale(0.7)',
                right:'-180px',
                '@media(max-width:950px)':{
                    position:'relative',
                    transform:'scale(1)',
                    '@media(max-width:950px)':{
                        right:'unset',
                        marginTop:'-200px',
                        left:'-50px',
                        '@media(max-width:628px)':{
                           transform: 'scale(0.8)',
                           '@media(max-width:481px)':{
                            transform:'scale(0.7)',
                            left:'-78px',
                            '@media(max-width:356px)':{
                                transform:'scale(0.6)',
                                left:'-55px'
                             }
                           }
                        }

                    }
                }
            }
        }
    },
    head:{
        fontStyle: "normal",
    
        fontWeight: "600",
        fontSize: "75px",
        fontFamily:'Aristotelica Display Trial',
        lineHeight: "78px",
        color: "#190714",
        width:'643px',
        margin: "auto",
        '@media(max-width:1205px)':{
            fontSize:'65px',
            '@media(max-width:1145px)':{
                fontSize:'53px',
                width:'460px',
                lineHeight:'60px',
                textAlign:'center',
                '@media(max-width:600px)':{
                    width:'383px',
                    fontSize:'41px',
                    lineHeight:'95%',
                    '@media(max-width:500px)':{
                        fontSize:'36px',
                        lineHeight:'95%',
                        width:'314px',
                        '@media(max-width:352px)':{
                            fontSize:'31px',
                        lineHeight:'95%',
                        width:'292px'
                        }
                    }
                }
            }
        }
    },
    headSpan:{
        color: "#FFABBC",
    },

    textA: {
        fontFamily:'Inter',
        fontWeight: "400",
        fontSize: "20px",
        lineHeight: "24px",
        color: "#190714",
        maxWidth: "463px",
        margin: "30px 0 50px 0",
        '@media(max-width:950px)':{
            margin:'20px auto',
            textAlign:'center',
            '@media(max-width:500px)':{
                width:'250px',
                fontSize:'12px',
                lineHeight:'15px'
            }
        }
    },
    btn:{
        background: "#FFABBC",
        borderRadius: "5px",
        fontFamily:"Aristotelica Display Trial",
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "26px",
        color: "#FFFFFF",
        border: "none",
        outline: "none",
        maxWidth: "329px",
        padding: "26px 32px 30px 33px",
        width: "329px",
        '@media(max-width:900px)':{
           margin:'auto',
           display: 'flex',
           justifyContent: 'center',
           '@media(max-width:432px)':{
            width:'235px',
            fontSize:'17px',
            padding:'12px 33px'
         }
        }
    }
});