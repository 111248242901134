import React from 'react'
import './Styles.scss'
import Pix1 from '../../assets/images/Pix1.png'
import Pix2 from '../../assets/images/Pix2.png'
import Pix3 from '../../assets/images/Pix3.png'
import playstore from  '../../assets/images/playstore.png';
import appstore from  '../../assets/images/appstore.png';
import {APP_LINK,PLAY_LINK} from "../../constants"

const index= () => {
  return (
    <div className='secondSection'>
        <div className="partA">
            <div className="imgContainer">
              <img src={Pix1} alt="" className='img1'/>
              <div className="flex">
                <img src={Pix2} alt="" className='img2'/>
              <img src={Pix3} alt="" className='img3'/>
              </div>
            </div>
        </div>
        <div className="partB">
            <h1 className='head'>Know more about <span className="center2">how you feel.</span> </h1>
            <p className="subhead">Get to know why you feel the way you do. You can better control your emotions when you know them ahead.</p>

            <div className='btnCon'>
            <a target='_blank' className="btn" href={PLAY_LINK}>Playstore<span style={{marginLeft:5}}><img src={playstore} alt="..." /></span></a>
            <a target='_blank'  className="btn" href={APP_LINK}>App Store<span style={{marginLeft:5}}><img src={appstore} alt="..."/></span></a>
            </div>
        </div>
    </div>
  )
}

export default index
