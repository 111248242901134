
export const ROUTES = {
  HOME: "/",
  ABOUT:'/about',
  BLOG:'/blog',
  BLOGDESCRITION:'/blog/:id',
  PRODUCT:'/products',
  PRODUCT_SINGLE:"/product/:id",
  PRIVACY:"/privacy-policy",
  
};
export const PLAY_LINK="https://play.google.com/store/apps/details?id=com.my_fairies&gl=gb&hl=gb&hl=en_GB";
export const APP_LINK="https://apps.apple.com/gb/app/myfairies/id6447424309"