// @flow
import React from "react";
import {css} from "aphrodite"
import styles from "./CardEStyles"
import days from "../../assets/images/days.png"
import elli from "../../assets/images/elli.png"
import appstore from "../../assets/images/appstore.png"
import playstore from "../../assets/images/playstore.png"
import { APP_LINK, PLAY_LINK } from "../../constants";

export default function CardEView() {


  return (
    <div className={css(styles.blogCard)}>
      <div className={css(styles.days)}>
        <img src={days} className={css(styles.Clock)} alt=""/>
      </div>
      <div className={css(styles.blogCardBox)}>
        <div className={css(styles.head)}>Have control over how you feel.</div>
        <p className={css(styles.textA)}>You can get to know why you crave for it. 😋</p>
        <div className={css(styles.abs)}>
          <div className={css(styles.box)}>
            <div className={css(styles.con)}>
              <img src={elli} /> <span className={css(styles.span)}>Period time</span>
            </div>
            <div className={css(styles.con)}>
              <img src={elli} /> <span className={css(styles.span)}>Ovulation</span>
            </div>
            <div className={css(styles.con)}>
              <img src={elli} /> <span className={css(styles.span)}>Period interval</span>
            </div>
            <div className={css(styles.con)}>
              <img src={elli} /> <span className={css(styles.span)}>Safe period</span>
            </div>
          </div>
          <div className={css(styles.btnCon)}>
            <a href={PLAY_LINK} target="_blank" className={css(styles.btn)}>Playstore<span style={{marginLeft:5}}><img src={playstore} /></span></a>
            <a href={APP_LINK} target="_blank" className={css(styles.btn)}>App Store<span style={{marginLeft:5}}><img src={appstore} /></span></a>
          </div>
        </div>
      </div>
    </div>
  );
}
