// @flow
import React from "react";

import { Link } from "react-router-dom";
import './WebFooterStyles.scss'
import { ROUTES } from "../../constants";

export default function WebFooterView() {
  return (

      <div className="footer">
       <div className="content w-259">
         <h2>MyFairies Healthcare Limited</h2>
         <p>Cardiff Bay Business Centre Lewis Road, Cardiff, Wales CF24 5BS</p>
         <p className="spec">13500847</p>
       </div>
       <div className="content">
         <h2>Company</h2>
         <ul>
           <li>
             <Link to={ROUTES.ABOUT}>
             About Us
             </Link>
           </li>
           <li>
             <Link to={ROUTES.PRODUCT}>
             Our Products
             </Link>
           </li>
           <li>
             <Link to={ROUTES.BLOG}>
             Our Blogs
             </Link>
           </li>
           <li>
             <Link to={ROUTES.PRIVACY}>
             Privacy and Policy
             </Link>
           </li>
          
         </ul>
       </div>
       <div className="content">
       <h2>Let’s Connect</h2>
         <ul>
         <li>
             <a target="_blank" href=" https://facebook.com/myfairieshq">
             Facebook
             </a>
           </li>
           <li>
             <a target="_blank" href="https://www.instagram.com/myfairieshq/">
             Instagram
             </a>
           </li>
           <li>
             <a target="_blank" href="https://www.twitter.com/myfairieshq/">
             Twitter
             </a>
           </li>
         </ul>
       </div>
       <div className="content  abs">
       <h2>Contact Us</h2>
         <ul>
         <li>
             <Link to="#">
             hello@myfairies.co.uk
             </Link>
           </li>
         </ul>
       </div>
       <div className="content"></div>
      </div>

  );
}
