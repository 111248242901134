// @flow
import React from "react";
import "./PageNotFoundStyles.scss";

export default function PageNotFoundView(props) {
  return (
    <div className="PageNotFoundpage">

      <section>
       
       PageNotFounds
      </section>

    </div>
  );
}
