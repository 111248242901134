// @flow
import React, { useState } from "react";
import { Bias, Drawer, Leave, Period, WebFooter, WebHeader } from "../../components";
import "./BlogStyles.scss";
import blog from '../../assets/images/blog.png'
import woman2 from '../../assets/images/woman2.png'
import Logo from '../../assets/images/Logo2.png'
import Search from '../../assets/images/Search.png'
import { allBlog } from "../blogData";

export default function BlogView() {
  const [sideToggle, setSideToggle] = useState(false);
  return (
    <div className="blog">
      <WebHeader logo={Logo} hcolor="#FFABBC" bgColor="#FFF" btcolor="#fff" btnColor="#FFABBC" color='#190714' show={sideToggle} click={() => setSideToggle(!sideToggle)} />
      <Drawer show={sideToggle} click={() => setSideToggle(!sideToggle)} />
      <section style={{ filter: sideToggle === true ? 'blur(8px)' : 'none' }}>
        <div className='leave2'>
          <h1 className="head">Latest News from<span className="diff">MyFairies </span></h1>
          <p className="subhead">Follow the MyFairies news for product announcements, feature updates, user stories, and posts about female menstrual health.</p>

        </div>
        <Bias />

<div >
        <div className="w1240">
        {
          allBlog.map(v => (
            
              <Period
                bg="#FFABBC"
                img={v.image}
                title={v.title}
                subtitle={v.subtitle}
                url={"/blog/"+v.slug}
              />
             
          ))
        }
</div>
            </div>
        <WebFooter />
      </section>

    </div>
  );
}
