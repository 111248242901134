export const allProducts = [
    {
        image: ["pad.svg","pad.svg","pad.svg","pad.svg"],
        description: "Our ultra-thin, ultra-absorbent pads are designed for your comfort and confidence during your period. With a soft, breathable top sheet and secure leak proof backing, they provide reliable protection throughout the day. Choose from various absorbencies to suit your needs, and feel free to move freely without worry.",
        name: "Sanitary Pads & towels",
        slug: "sanitary-pads-towels",
        category: "SANITARY",
        price: "18.40",
        sizes: ['Light','Regular','Heavy','Super Heavy']
    }, {
        image: ["menstural-cups.png"],
        description: "Join the sustainable period movement with our reusable menstrual cup. Made from soft, medical-grade silicone, it collects your flow comfortably and securely for mess-free periods. Enjoy long-lasting protection for up to 12 hours, minimizing changes and offering ultimate convenience during your cycle.",
        name: "Menstrual Cups",
        slug: "menstural-cups",
        category: "SANITARY",
        price: "9.99",
        sizes: ['Light','Regular','Heavy','Super Heavy']
    }, {
        image: ["tampons.png"],
        description: "Experience ultimate period freedom with our ultra-absorbent tampons. Discreet and comfortable, they offer personalized leak protection throughout your cycle. Available in various absorbencies, they allow you to stay active and confident, no matter what your day brings.",
        name: "Tampons",
        slug: "tampons",
        category: "SANITARY",
        price: "13.19",
        sizes: ['Light','Regular','Heavy','Super Heavy']
    }, {
        image: ["hot-water-bag.png"],
        description: "Cramp relief, naturally. Our soothing hot water bag provides comforting warmth to ease period discomfort. Simply fill, heat, and cuddle up for targeted pain relief.",
        name: "Hot Water bag",
        slug: "hot-water-bag",
        category: "SANITARY",
        price: "7.50",
        sizes: ['Light','Regular','Heavy','Super Heavy']
    }, {
        image: ["birth-control-pills.png"],
        description: "Take control of your cycle with our discreet birth control pills. Offered in various formulations, they help regulate your periods and offer peace of mind, allowing you to focus on what matters most.",
        name: "Birth Control Pills",
        slug: "birth-control-pills",
        category: "SANITARY",
        price: "10",
        sizes: ['Light','Regular','Heavy','Super Heavy']
    }, {
        image: ["male-condom.png"],
        description: "Protect yourself and your partner with our reliable condoms. Made with premium materials latex for a comfortable fit and effective protection against unwanted pregnancy and sexually transmitted infections (STIs). Remember, safe sex is essential for a healthy you and your partner.",
        name: "Male Condoms",
        slug: "male-condom",
        category: "SANITARY",
        price: "10",
        sizes: ['Light','Regular','Heavy','Super Heavy']
    }, {
        image: ["water-oil.png"],
        description: "Enhance your comfort and intimacy with our water and oil based personal lubricant. This gentle formula is designed to mimic natural lubrication, providing a smooth, long-lasting experience. It's pH-balanced, condom-safe, and easy to clean up with just water. Enjoy worry-free intimacy with a product you can trust.",
        name: "Water & Oil Lubricant",
        slug: "water-oil",
        category: "SANITARY",
        price: "7.50",
        sizes: ['Light','Regular','Heavy','Super Heavy']
    }, {
        image: ["panty-liner.png"],
        description: "Discreet and comfortable protection throughout your day. Our ultra-thin pantyliners are designed to absorb light discharge and spotting, keeping you fresh and confident. Choose from various absorbencies for personalized comfort, and feel free to move worry-free.",
        name: "Panty Liners",
        slug: "panty-liner",
        category: "SANITARY",
        price: "50",
        sizes: ['Light','Regular','Heavy','Super Heavy']
    }, {
        image: ["30mins-session.png"],
        description: "Seeking support for your menstrual cycle? Our 30-minute session connects you with a dedicated professional for a focused discussion. Gain valuable insights and personalized recommendations tailored to your unique needs and questions. This session provides a safe space to address any concerns you may have about your cycle. Ask questions, explore options, and gain the clarity and confidence to make informed decisions about your menstrual health.",
        name: "Thirty Minutes Session with a Professional",
        slug: "30mins-session",
        category: "BOOKING",
        price: "10",
        sizes: []
    }, {
        image: ["60mins-session.png"],
        description: "Seeking support for your menstrual cycle? Our 60-minute session connects you with a dedicated professional for a focused discussion. Gain valuable insights and personalized recommendations tailored to your unique needs and questions. This session provides a safe space to address any concerns you may have about your cycle. Ask questions, explore options, and gain the clarity and confidence to make informed decisions about your menstrual health.",
        name: "Sixty Minutes Session with a Professional",
        slug: "60mins-session",
        category: "BOOKING",
        price: "18",
        sizes: []
    },
    {
         image: ["Aquarius-Black.png","Aquarius-Blue.png","Aquarius-Green.png","Aquarius-Blue.png","Aquarius-Red.png","Aquarius-White.png"],
        description: "Channel your inner cosmos with our unique line of Zodiac t-shirts! Each shirt features a bold and beautiful design of your chosen astrological sign, available in a spectrum of colors to perfectly match your personality. These t-shirts offer a comfortable fit and lasting quality. Whether you're a fiery Leo rocking a bold red tee, a mysterious Scorpio sporting a deep emerald green, these shirts are the perfect way to express your celestial side.",
        name: "Aquarius Tshirt",
        slug: "aquarius-tshirt",
        category: "CLOTHING",
        price: "10",
        sizes: ['Small','Medium','Large','Extra Large']
    },
    {
         image: ["Aries-Black.png","Aries-Blue.png","Aries-Green.png","Aries-Blue.png","Aries-Red.png","Aries-White.png"],
        description: "Channel your inner cosmos with our unique line of Zodiac t-shirts! Each shirt features a bold and beautiful design of your chosen astrological sign, available in a spectrum of colors to perfectly match your personality. These t-shirts offer a comfortable fit and lasting quality. Whether you're a fiery Leo rocking a bold red tee, a mysterious Scorpio sporting a deep emerald green, these shirts are the perfect way to express your celestial side.",
        name: "Aries Tshirt",
        slug: "aries-tshirt",
        category: "CLOTHING",
        price: "10",
        sizes: ['Small','Medium','Large','Extra Large']
    },
    {
         image: ["Cancer-Black.png","Cancer-Blue.png","Cancer-Green.png","Cancer-Blue.png","Cancer-Red.png","Cancer-White.png"],
        description: "Channel your inner cosmos with our unique line of Zodiac t-shirts! Each shirt features a bold and beautiful design of your chosen astrological sign, available in a spectrum of colors to perfectly match your personality. These t-shirts offer a comfortable fit and lasting quality. Whether you're a fiery Leo rocking a bold red tee, a mysterious Scorpio sporting a deep emerald green, these shirts are the perfect way to express your celestial side.",
        name: "Cancer Tshirt",
        slug: "cancer-tshirt",
        category: "CLOTHING",
        price: "10",
        sizes: ['Small','Medium','Large','Extra Large']
    },
    {
         image: ["Capricon-Black.png","Capricon-Blue.png","Capricon-Green.png","Capricon-Blue.png","Capricon-Red.png","Capricon-White.png"],
        description: "Channel your inner cosmos with our unique line of Zodiac t-shirts! Each shirt features a bold and beautiful design of your chosen astrological sign, available in a spectrum of colors to perfectly match your personality. These t-shirts offer a comfortable fit and lasting quality. Whether you're a fiery Leo rocking a bold red tee, a mysterious Scorpio sporting a deep emerald green, these shirts are the perfect way to express your celestial side.",
        name: "Capricon Tshirt",
        slug: "capricon-tshirt",
        category: "CLOTHING",
        price: "10",
        sizes: ['Small','Medium','Large','Extra Large']
    },
    {
         image: ["Gemini-Black.png","Gemini-Blue.png","Gemini-Green.png","Gemini-Blue.png","Gemini-Red.png","Gemini-White.png"],
        description: "Channel your inner cosmos with our unique line of Zodiac t-shirts! Each shirt features a bold and beautiful design of your chosen astrological sign, available in a spectrum of colors to perfectly match your personality. These t-shirts offer a comfortable fit and lasting quality. Whether you're a fiery Leo rocking a bold red tee, a mysterious Scorpio sporting a deep emerald green, these shirts are the perfect way to express your celestial side.",
        name: "Gemini Tshirt",
        slug: "gemini-tshirt",
        category: "CLOTHING",
        price: "10",
        sizes: ['Small','Medium','Large','Extra Large']
    },
    {
         image: ["Leo-Black.png","Leo-Blue.png","Leo-Green.png","Leo-Blue.png","Leo-Red.png","Leo-White.png"],
        description: "Channel your inner cosmos with our unique line of Zodiac t-shirts! Each shirt features a bold and beautiful design of your chosen astrological sign, available in a spectrum of colors to perfectly match your personality. These t-shirts offer a comfortable fit and lasting quality. Whether you're a fiery Leo rocking a bold red tee, a mysterious Scorpio sporting a deep emerald green, these shirts are the perfect way to express your celestial side.",
        name: "Leo Tshirt",
        slug: "leo-tshirt",
        category: "CLOTHING",
        price: "10",
        sizes: ['Small','Medium','Large','Extra Large']
    },
    {
         image: ["Libra-Black.png","Libra-Blue.png","Libra-Green.png","Libra-Blue.png","Libra-Red.png","Libra-White.png"],
        description: "Channel your inner cosmos with our unique line of Zodiac t-shirts! Each shirt features a bold and beautiful design of your chosen astrological sign, available in a spectrum of colors to perfectly match your personality. These t-shirts offer a comfortable fit and lasting quality. Whether you're a fiery Leo rocking a bold red tee, a mysterious Scorpio sporting a deep emerald green, these shirts are the perfect way to express your celestial side.",
        name: "Libra Tshirt",
        slug: "libra-tshirt",
        category: "CLOTHING",
        price: "10",
        sizes: ['Small','Medium','Large','Extra Large']
    },
    {
         image: ["Pisces-Black.png","Pisces-Blue.png","Pisces-Green.png","Pisces-Blue.png","Pisces-Red.png","Pisces-White.png"],
        description: "Channel your inner cosmos with our unique line of Zodiac t-shirts! Each shirt features a bold and beautiful design of your chosen astrological sign, available in a spectrum of colors to perfectly match your personality. These t-shirts offer a comfortable fit and lasting quality. Whether you're a fiery Leo rocking a bold red tee, a mysterious Scorpio sporting a deep emerald green, these shirts are the perfect way to express your celestial side.",
        name: "Pisces Tshirt",
        slug: "pisces-tshirt",
        category: "CLOTHING",
        price: "10",
        sizes: ['Small','Medium','Large','Extra Large']
    },
    {
         image: ["Scorpio-Black.png","Scorpio-Blue.png","Scorpio-Green.png","Scorpio-Blue.png","Scorpio-Red.png","Scorpio-White.png"],
        description: "Channel your inner cosmos with our unique line of Zodiac t-shirts! Each shirt features a bold and beautiful design of your chosen astrological sign, available in a spectrum of colors to perfectly match your personality. These t-shirts offer a comfortable fit and lasting quality. Whether you're a fiery Leo rocking a bold red tee, a mysterious Scorpio sporting a deep emerald green, these shirts are the perfect way to express your celestial side.",
        name: "Scorpio Tshirt",
        slug: "scorpio-tshirt",
        category: "CLOTHING",
        price: "10",
        sizes: ['Small','Medium','Large','Extra Large']
    },
    {
         image: ["Sogittarius-Black.png","Sogittarius-Blue.png","Sogittarius-Green.png","Sogittarius-Blue.png","Sogittarius-Red.png","Sogittarius-White.png"],
        description: "Channel your inner cosmos with our unique line of Zodiac t-shirts! Each shirt features a bold and beautiful design of your chosen astrological sign, available in a spectrum of colors to perfectly match your personality. These t-shirts offer a comfortable fit and lasting quality. Whether you're a fiery Leo rocking a bold red tee, a mysterious Scorpio sporting a deep emerald green, these shirts are the perfect way to express your celestial side.",
        name: "Sogittarius Tshirt",
        slug: "sogittarius-tshirt",
        category: "CLOTHING",
        price: "10",
        sizes: ['Small','Medium','Large','Extra Large']
    },
    {
         image: ["Taurus-Black.png","Taurus-Blue.png","Taurus-Green.png","Taurus-Blue.png","Taurus-Red.png","Taurus-White.png"],
        description: "Channel your inner cosmos with our unique line of Zodiac t-shirts! Each shirt features a bold and beautiful design of your chosen astrological sign, available in a spectrum of colors to perfectly match your personality. These t-shirts offer a comfortable fit and lasting quality. Whether you're a fiery Leo rocking a bold red tee, a mysterious Scorpio sporting a deep emerald green, these shirts are the perfect way to express your celestial side.",
        name: "Taurus Tshirt",
        slug: "taurus-tshirt",
        category: "CLOTHING",
        price: "10",
        sizes: ['Small','Medium','Large','Extra Large']
    },
    {
         image: ["Virgo-Black.png","Virgo-Blue.png","Virgo-Green.png","Virgo-Blue.png","Virgo-Red.png","Virgo-White.png"],
        description: "Channel your inner cosmos with our unique line of Zodiac t-shirts! Each shirt features a bold and beautiful design of your chosen astrological sign, available in a spectrum of colors to perfectly match your personality. These t-shirts offer a comfortable fit and lasting quality. Whether you're a fiery Leo rocking a bold red tee, a mysterious Scorpio sporting a deep emerald green, these shirts are the perfect way to express your celestial side.",
        name: "Virgo Tshirt",
        slug: "virgo-tshirt",
        category: "CLOTHING",
        price: "10",
        sizes: ['Small','Medium','Large','Extra Large']
    }
]