import Routing from "./router";


function App() {
  return (
    <>
     <Routing />
    </>
  );
}

export default App;
