import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { ROUTES } from "../constants";
import {
  HomePage,
  PageNotFound,
  Blog,
  BlogDescription,
  Product,
  ProductSingle,
  About,
  Privacy
} from "../pages";
import LayoutWrapper from "./LayoutWrapper";

const NoAuthRoute = ({ ...props }) => {
  return (
    <LayoutWrapper>
      <Route {...props} />
    </LayoutWrapper>
  );
};

class Routers extends React.PureComponent {
  render() {
    const { user } = this.props;
    const repeatedProps = {
      user,
      exact: true,
      forAdminOnly: false,
    };
    return (
      <Router>
        <div>
          <Switch>
            <NoAuthRoute
              path={ROUTES.HOME}
              component={HomePage}
              {...repeatedProps}
            />
             <NoAuthRoute
              path={ROUTES.ABOUT}
              component={About}
              {...repeatedProps}
            />

            <NoAuthRoute
              path={ROUTES.BLOG}
              component={Blog}
              {...repeatedProps}
            />
             <NoAuthRoute
              path={ROUTES.BLOGDESCRITION}
              component={BlogDescription}
              {...repeatedProps}
            />
            <NoAuthRoute
              path={ROUTES.PRODUCT_SINGLE}
              component={ProductSingle}
              {...repeatedProps}
            />
             <NoAuthRoute
              path={ROUTES.PRIVACY}
              component={Privacy}
              {...repeatedProps}
            />
             <NoAuthRoute
              path={ROUTES.PRODUCT}
              component={Product}
              {...repeatedProps}
            />

            {/* Keep this in last always */}
            <NoAuthRoute path={Route.PAGE_NOT_FOUND} component={PageNotFound} />
          </Switch>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  user,
});

const actions = {};

export default connect(mapStateToProps, actions)(Routers);
