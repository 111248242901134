import React from 'react'
import './Styles.scss'
import Care from '../../assets/images/Care/Care.png'
import appstore from "../../assets/images/appstore.png"
import playstore from "../../assets/images/playstore.png"
import { APP_LINK, PLAY_LINK } from '../../constants'

const index = () => {
  return (
    <div className='care'>
        <img src={Care} alt="" className='img'/>
        <div className='flex'>
            <h1 className="title">
            Need <span className="diff" style={{fontFamily:"Aristotelica Display Trial"}}>someone</span> to talk to?
            </h1>
            <p className='subtitle'>
            Are you experiencing pain or worried about anything, then you have to speak with our doctors or therapists. 
            We work with a team of medical doctors, gynecologists,
              and female health therapists to provide the best support for every woman.
            </p>
            <div className="container">
            <div className='btnCon'>
              <a href={PLAY_LINK} className='btn'>Playstore<span><img src={playstore} alt="" /></span></a>
              <a href={APP_LINK} className='btn'>App Store<span><img src={appstore} alt=""/></span></a>
            </div>
           </div>
        </div>
    </div>
  )
}

export default index