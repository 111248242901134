// @flow
import React from "react";

import CardCView from "./CardCView";

export default class CardCController extends React.Component {
 

  render() {
    return (
      <CardCView {...this.props} />
    );
  }
}
