// @flow
import React from "react";
import { css } from "aphrodite"
import styles from "./CardAStyles"
import myflairlogo2 from "../../assets/images/myflairlogo2.png"
import women from "../../assets/images/women.png"
import Uni from "../../assets/images/Uni.png"

export default function CardAView(props) {

  /*  const topContainer = useRef();
  
    useEffect(() => {
      // To make sure page starts from the top
      topContainer.current.scrollIntoView({ block: "end", behavior: "smooth" });
    }, []);
   */
  return (
    <div className={css(styles.blogCard)}>
      <div className={css(styles.flex)} style={{marginTop:15}}>
        <h1 className={css(styles.head)}>Connect with us</h1>
        <p>+447384000642</p>
        <div className={css(styles.line)}></div>
        <img src={Uni} alt="" className={css(styles.uni)} />
      </div>
      <div className={css(styles.container)}>
        <div className={css(styles.grid)}>
          <div className={css(styles.myflairlogo)}>
            <img src={myflairlogo2} alt="" />
          </div>
          <p className={css(styles.textA)}>The Unique Parts of a Woman's Bod</p>
          <div className={css(styles.textB)}>The body is an entity that is made up of different organs. These organs put together are responsible for the entity called a body. As a woman, It is natural to be familiar with the external parts of your body (eyes, ears, mouth, hand) and even the private ones (breast, thigh, among others).</div>
          <div className={css(styles.blogCardBox)}>
            <img src={women} alt="" />
          </div>
        </div>

        <div className={css(styles.grid, styles.bg1)}>
          <div className={css(styles.myflairlogo)}>
            <img src={myflairlogo2} alt="" />
          </div>
          <p className={css(styles.textA)}>The Essence of Emergency Sanitary Kits</p>
          <div className={css(styles.textB)}>Menstruation can be unpredictable at times. Your period might come when you least expect it, this is the main reason why you have to be proactive toward your monthly flow.</div>
          <div className={css(styles.blogCardBox)}>
            <img src={women} alt="" />
          </div>
        </div>

        <div className={css(styles.grid, styles.bg2)}>
          <div className={css(styles.myflairlogo)}>
            <img src={myflairlogo2} alt="" />
          </div>
          <p className={css(styles.textA)}>How to Deal with Emotional Stress during your Period</p>
          <div className={css(styles.textB)}>It can be very unpredictable to determine how your emotions would play out during your period. It is very common for you to feel depressed, sad, anxious before or during your period, sometimes it might lead to crying out of confusion.</div>
          <div className={css(styles.blogCardBox)}>
            <img src={women} alt="" />
          </div>
        </div>

      
      </div>
    </div>
  );
}
