import styled from 'styled-components'
import { PayPalButton } from "react-paypal-button-v2";
import { Input,notification } from 'antd'
import { SmileOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import {devices} from '../../styles/helper';
import emailjs from '@emailjs/browser';
const Checkout = (props) => {
    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [address, setAddress] = useState("")
    const [email, setEmail] = useState("")
    const [payment, setPayment] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    const openNotification = () => {
      api.open({
        message: 'Order Successful',
        description:
          `We're excited to inform you that your order has been successfully completed! Your satisfaction is our top priority, and we're delighted to have had the opportunity to serve you. `,
        icon: <SmileOutlined style={{ color: '#108ee9' }} />,
      });
    };
    useEffect(() => {
        if (name && phone && email && address) {
            setPayment(true)
        } else {
            setPayment(false)
        }
    }, [name, phone, email, address])
    const sendEmail = async (data) => {
        const templateParams = {
            to_email: email,
            to_name: name,
            order: data.orderID,
            date: new Date().toLocaleDateString(),
            total: "£"+(parseFloat(product.price) + 3).toFixed(2),
            delivery: "£3.00",
            product: product.name,
            quantity: props.quantity,
            price: (parseFloat(product.price)).toFixed(2),
            to_phone: phone,
            to_address: address
        };
      
            await emailjs.send('service_4rmvsoc', 'template_1r7liuf', templateParams, {
                publicKey: 'JoBL9Ho4ygxSQ3nj5',
                // Do not allow headless browsers
                blockHeadless: true,
            })
                .then((response) => {
                    console.log('SUCCESS!', response.status, response.text);
                }, (err) => {
                    console.log('FAILED...', err);
                });
               /*  await emailjs.send('service_4rmvsoc', 'template_1r7liuf', {...templateParams,to_email:"order@myfairies.co.uk"}, {
                    publicKey: 'JoBL9Ho4ygxSQ3nj5',
                    // Do not allow headless browsers
                    blockHeadless: true,
                })
                    .then((response) => {
                        console.log('SUCCESS!', response.status, response.text);
                    }, (err) => {
                        console.log('FAILED...', err);
                    }); */
                openNotification()

    }
    const product = props.product
    return (
        <Container>
            {contextHolder}
            <PaymentSection>
                <CustomerDetails>
                    <Input value={name} onChange={(e) => setName(e.target.value)} placeholder='Full Name' />
                    <Input value={phone} onChange={(e) => setPhone(e.target.value)} placeholder='Phone Number' />
                    <Input value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Email' />
                    <Input.TextArea
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        placeholder="Address"
                        autoSize={{ minRows: 2, maxRows: 6 }}
                    />
                </CustomerDetails>
                <Payment>
                    {
                        payment && (
                            <PayPalButton
                                style={{ width: "100%" }}
                                amount={(parseFloat(product.price) + 3)}

                                
                                // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                                onSuccess={async (details, data) => {
                                    await sendEmail(data)
                                    props.setIsModalOpen(false)

                                    // OPTIONAL: Call your server to save the transaction

                                }}
                                options={{
                                    currency:"GBP",
                                    clientId: "AT8C3-QgAd6G_dxUOk9uib-QOPlcB6_JbQTfwV1nB5p1iLTBQWdQqlLywAYHGAx2oyxBXCheXcslsHco"
                                }}
                            />
                        )
                    }

                </Payment>
            </PaymentSection>
            <Summary>
                <OrderTitle>Order Summary</OrderTitle>
                <OrderName>
                    {product.name}
                    <span>£{(parseFloat(product.price)).toFixed(2)}</span>
                </OrderName>
                <hr />
                <OrderPrice>Service Charge <span>£0.00</span></OrderPrice>
                <OrderPrice>Value Added Tax <span>£0.00</span></OrderPrice>
                <OrderPrice>Delivery Fee <span>£3.00</span></OrderPrice>
                <OrderName>Total <span>£{(parseFloat(product.price)+3).toFixed(2)}</span></OrderName>
            </Summary>
        </Container>
    )
}

export default Checkout
const Container = styled.div`
padding:10px 20px;
display:flex;
background-color:#FFF7F8;
gap:20px;
flex-direction: column-reverse;
  @media only screen and ${devices.md} {
    flex-direction: row;
  }

`;
const Payment = styled.div`
padding:20px 20px;
flex:1;
background-color:#FFFFFF;
box-shadow: 0px 10px 15px 0px #5282FF0F;
`;
const PaymentSection = styled.div`
flex:1;
display:flex;
flex-direction:column;
gap:20px;
  
`;
const CustomerDetails = styled.div`
padding:20px 20px;
flex:1;
display:flex;
flex-direction:column;
gap:20px;
background-color:#FFFFFF;
box-shadow: 0px 10px 15px 0px #5282FF0F;
`;
const Summary = styled.div`
padding:10px 20px;
display:flex;
flex-direction:column;
background-color:#FFFFFF;
width:300px;
box-shadow: 0px 10px 15px 0px #5282FF0F;
margin-left:auto;
`;
const OrderTitle = styled.p`
color:#190714;
    font-family: 'Aristotelica Display Trial';
font-size: 20px;
font-weight: 600;
`;
const OrderName = styled.div`
margin-top:15px;
color:#190714;
    font-family: 'Aristotelica Display Trial';
font-size: 18px;
font-weight: 600;
display:flex;
align-items:center;
span{
    font-family: Quicksand;
font-size: 18px;
font-weight: 700;
margin-left:auto;

}
`;
const OrderPrice = styled.div`
color:#190714;
    font-family: 'Inter';
font-size: 12px;
font-weight: 500;
display:flex;
align-items:center;
span{
    font-family: Inter;
font-size: 12px;
font-weight: 500;
margin-left:auto;

}
`;

