export const allBlog = [
    {
        title: "ARE YOU ANXIOUS ABOUT",
        image: "/blog-image/period-emojis.png",
        slug: "period-emojis-breaking-the-taboo",
        subtitle: "Period Emojis: Breaking the Taboo",
        details: `<p style="text-align: justify;"><h2><span > Period Emojis: Breaking the Taboo and Normalizing Period Talk</span></h2></p>
    <p style="text-align: justify;"><em><b > It&apos;s time to break the taboo and get real about something that&apos;s as natural as breathing&mdash;periods!</b></em></p>
    <p style="text-align: justify;"><span >Hey there, awesome teens! It&apos;s time to break the taboo and get real about something that&apos;s as natural as breathing&mdash;periods! Yep, we&apos;re diving into the world of period emojis and why it&apos;s so important to normalize period talk. So grab your favorite snacks and let&apos;s get chatting!</span></p>
    <p style="text-align: justify;"><span >First things first, let&apos;s talk about why period emojis are the bomb dot com. I mean, think about it&mdash;emojis are like our own little language, right? They help us express ourselves in fun and creative ways, whether we&apos;re feeling happy, sad, or totally obsessed with that new Netflix show. So why not have emojis that represent something as common as periods?</span></p>
    <p style="text-align: justify;"><span >Enter: period emojis! From tampons and pads to heating pads and chocolate bars, these emojis are all about keeping it real when it comes to that time of the month. They&apos;re a playful way to start conversations about periods and break down the stigma surrounding menstrual health. Because let&apos;s face it, periods are nothing to be ashamed of&mdash;they&apos;re a natural part of life!</span></p>
    <p style="text-align: justify;"><span >But why stop at emojis? Let&apos;s take it a step further and talk about why normalizing period talk is so freaking important. When we open up about our periods and share our experiences, we&apos;re breaking down barriers and creating a world where everyone feels comfortable talking about their bodies and their health. Periods shouldn&apos;t be a taboo topic&mdash;they should be celebrated and embraced!</span></p>
    <p style="text-align: justify;"><span >So how can you join the period positivity party? It&apos;s simple, really! Start by talking openly about periods with your friends, family, and even your healthcare provider. Share your own experiences and let others know that it&apos;s totally okay to talk about periods. And hey, if you&apos;re feeling extra bold, use those period emojis to add a little flair to your conversations!</span></p>
    <p style="text-align: justify;"><span >Remember, periods are nothing to be ashamed of. They&apos;re a sign that your body is healthy and doing its thing, and that&apos;s something to celebrate! So let&apos;s keep the period talk going and break down those barriers, one emoji at a time. Together, we can create a world where period talk is no big deal&mdash;because let&apos;s be real, it&apos;s really not! 💁&zwj;♀️🌸✨</span></p>`
    }, {
        title: "ARE YOU ANXIOUS ABOUT",
        image: "/blog-image/period-talk.png",
        slug: "why-your-period-may-be-late",
        subtitle: "Why Your Period May Be Late",
        details: `<p><h2><span >Oops, Did Your Period Miss Its Cue? Let&apos;s Unravel the Mystery!</span></h2></p>
   
    <p><span >Hey there, fellow teens! So, let&apos;s talk about a topic that might make some of us blush a bit but is totally normal and natural: menstrual health. Specifically, why that time of the month might decide to hit the snooze button and delay its arrival. Yep, we&apos;re diving into the reasons behind why your period might be fashionably late.</span></p>
   
    <ol>
        <li style="list-style-type:decimal;">
            <p><strong><span >Stress Monster</span></strong><span >: Picture this: You&apos;ve got a mountain of homework, a pop quiz looming, and a drama-filled group chat buzzing away. Stress levels? Through the roof! Well, guess what? Stress can give your period a serious case of stage fright. When stress hormones like cortisol are pumping, your body might decide now&apos;s not the time to bring on the crimson tide.</span></p>
        </li>
    </ol>
   
    <ol start="2">
        <li style="list-style-type:decimal;">
            <p><strong><span >Hormonal Rollercoaster</span></strong><span >: Ah, hormones &ndash; those little troublemakers that can turn us into emotional acrobats. Sometimes, they just decide to throw a curveball and mess with our menstrual cycle. Whether it&apos;s because of changes in weight, sleep patterns, or just your body doing its own thing, hormones can be the sneaky culprits behind a delayed period.</span></p>
        </li>
    </ol>
   
   
    <ol start="3">
        <li style="list-style-type:decimal;">
            <p><strong><span >Nutrition Matters</span></strong><span >: Ever heard the phrase &quot;you are what you eat&quot;? Well, turns out, what you munch on can play a role in when Aunt Flo comes to visit. If your diet is lacking in important nutrients or if you&apos;ve recently made some drastic changes to what&apos;s on your plate, your period might decide to take a rain check until things stabilize.</span></p>
        </li>
    </ol>
   
    <ol start="4">
        <li style="list-style-type:decimal;">
            <p><strong><span >Exercise Overload</span></strong><span >: We all know exercise is important for keeping our bodies in tip-top shape, but too much of a good thing can sometimes throw things out of whack. Intense workouts or suddenly ramping up your exercise routine can send signals to your body that now&apos;s not the best time to start shedding uterine lining.</span></p>
        </li>
    </ol>
   
   
    <ol start="5">
        <li style="list-style-type:decimal;">
            <p><strong><span >Sleepyhead Syndrome</span></strong><span >: Ever pulled an all-nighter binge-watching your favorite series? Guilty as charged! Well, lack of sleep can mess with your body&apos;s internal clock, which can in turn mess with your menstrual cycle. So, make sure to catch those Z&apos;s!</span></p>
        </li>
    </ol>
   
    <ol start="6">
        <li style="list-style-type:decimal;">
            <p><strong><span >Sick Days</span></strong><span >: Your body&apos;s pretty smart &ndash; it knows when it&apos;s not feeling 100%. So, if you&apos;re battling a cold or flu, your period might decide to take a back seat until you&apos;re back on your feet again.</span></p>
        </li>
    </ol>
   
   
    <ol start="7">
        <li style="list-style-type:decimal;">
            <p><strong><span >Body Changes</span></strong><span >: Hey, we&apos;re all growing up, right? And with that comes a whole bunch of changes &ndash; including changes to our menstrual cycles. It&apos;s totally normal for your period to be a bit irregular as your body figures things out.</span></p>
        </li>
    </ol>
   
    <p><span >But hey, no need to panic if your period decides to play hide and seek. If you&apos;re worried or if it&apos;s been MIA for longer than usual, it&apos;s always a good idea to chat with a trusted adult or healthcare provider.</span></p>
   
    <p><span >Remember, our bodies are pretty amazing but sometimes they like to keep us on our toes. So, embrace the quirks, stay healthy, and don&apos;t stress &ndash; your period will show up when it&apos;s good and ready!</span></p>`
    }, {
        title: "ARE YOU ANXIOUS ABOUT",
        image: "/blog-image/period-2.png",
        slug: "period-starting-signs",
        subtitle: "Period Starting Signs",
        details: `<p><strong><span style="">SIGNS YOU&apos;RE ABOUT TO START YOUR PERIOD.</span></strong></p>
   
    <p><em><span style="">Five ways to know that your period is getting closer.</span></em></p>
   
    <p><span style="">The most important thing to know about a woman&apos;s body is the reaction to the menstrual process both before it comes and after it comes. Generally, the reaction doesn&apos;t come the same for every woman. It depends on the way the body works.&nbsp;</span></p>
   
    <p><span style="">However, it is important to know the signs indicating your juice is coming so you can get prepared and manage the signs knowing the reason for its presence. Here are five notable signs to know your period is getting closer:</span></p>
   
    <p><span style="">1. Tender Breasts</span></p>
    <p><span style="">This is a widely known sign that is common amongst women. This occurs as a result of the changes in hormone levels before menstruation which can cause pain, swelling or tenderness in your breasts.</span></p>
   
    <p><span style="">2. Acne</span></p>
    <p><span style="">The skin is another place to know that your period is closer. Menstruation causes changes in hormones so most of the signs result from the hormonal changes. There&apos;s an increase in sebum production which in turn clogs the pores on the face and leads to pimples.</span></p>
   
    <p><span style="">3. Changes in vaginal discharge</span></p>
    <p><span style="">This differs in every woman. A sign your period is coming is that there are notable changes in your vaginal discharge. While for some women the vaginal discharge reduces drastically, It could be on the rise for other women. Whatever yours is, you can detect your juice is coming when you notice changes in your vaginal discharge.</span></p>
   
    <p><span style="">4. Mood swings</span></p>
    <p><span style="">This is another sign of a period coming soon. Oestrogen which is the hormone responsible to lift up your mood is known to drop a little just before your period arrives. This in conjunction with a raised progesterone level can make you feel irritated, moody or annoyed.</span></p>
   
    <p><span style="">5. Cramps</span></p>
    <p><span style="">It is widely known that cramp occurs during period but it could also come before your period arrives. If you ever notice intense pains in your stomach, it could mean that your period is coming soon.</span></p>
   
   
    <p><span style="">I implore you to treat these signs as an announcement that your period is coming, start preparing yourself (get all your sanitary items ready) and manage the signs well so you won&apos;t get yourself stressed out.&nbsp;</span></p>
   
    <p><span style="">For instance, since you know you have mood swings before your period arrives, treat it as a sign and not the reason you snap at anybody or get cranky. This would help you keep your emotions in check.</span></p>
   
    <p><span style="">The next post would be centered on how you can manage the signs and the things you can do to prepare for your period.&nbsp;</span></p>
   
    <p><span style="">Stay blessed, beautiful woman!</span></p>`
    }, {
        title: "ARE YOU ANXIOUS ABOUT",
        image: "/blog-image/period.png",
        slug: "period-myths-debunked",
        subtitle: "Period Myths Debunked",
        details: `<p style="text-align: justify;"><strong><span > Period Myths you need to Debunk</span></strong></p>
    <p style="text-align: justify;"><em><span >Let&rsquo;s bust some period myths that have been floating around like old homework assignments.</span></em></p>
    <p style="text-align: justify;"><span >Hey there, awesome teens!</span></p>
    <p style="text-align: justify;"><span >It&apos;s time to set the record straight and bust some period myths that have been floating around like old homework assignments. Yep, we&apos;re separating fact from fiction when it comes to that time of the month. So grab a cozy spot, because we&apos;re about to spill the tea on all things period-related!</span></p>
    <p style="text-align: justify;"><strong><span >Myth #1:</span></strong><span >&nbsp;You can&apos;t swim during your period. Fact: Girl, jump right into that pool! Contrary to popular belief, there&apos;s absolutely no reason to skip out on swimming when you&apos;re on your period. Your trusty period products&mdash;whether it&apos;s tampons, menstrual cups, or period-proof swimwear&mdash;have got your back (or should we say, your front?). So go ahead, make a splash!</span></p>
    <p style="text-align: justify;"><strong><span >Myth #2:</span></strong><span >&nbsp;You shouldn&apos;t exercise during your period. Fact: Say hello to your new workout buddy&mdash;your period! Exercise can actually help alleviate period cramps and boost your mood by releasing those feel-good endorphins. Whether you&apos;re hitting the gym, going for a run, or busting out some killer dance moves, staying active during your period can help you feel like the fierce warrior that you are.</span></p>
    <p style="text-align: justify;"><strong><span >Myth #3:</span></strong><span >&nbsp;You can&apos;t get pregnant during your period. Fact: Ah, the age-old question! While it&apos;s less likely to get pregnant during your period, it&apos;s not impossible. Sperm can hang around in your body for several days, so if you have a shorter menstrual cycle or ovulate early, there&apos;s still a chance of pregnancy. Always play it safe and use protection if you&apos;re sexually active, regardless of where you are in your cycle.</span></p>
    <p style="text-align: justify;"><strong><span >Myth #4:</span></strong><span >&nbsp;Period blood is dirty. Fact: Let&apos;s clear this up once and for all&mdash;period blood is not dirty! It&apos;s a natural and normal part of the menstrual cycle. In fact, period blood is made up of blood, tissue, and uterine lining, just like any other blood in your body. So next time someone tries to tell you that period blood is gross, kindly remind them that it&apos;s as natural as breathing.</span></p>
    <p style="text-align: justify;"><strong><span >Myth #5:</span></strong><span >&nbsp;PMS is just a myth. Fact: Oh, if only that were true! PMS (Premenstrual Syndrome) is as real as Monday mornings. From mood swings to bloating to cravings for all the chocolate in the world, PMS symptoms can vary from person to person. But rest assured, you&apos;re not alone! Talk to your friends, family, or healthcare provider if you&apos;re feeling overwhelmed by PMS symptoms&mdash;they&apos;re here to help!</span></p>
    <p style="text-align: justify;"><span >So there you have it, fabulous teens&mdash;period myths, debunked! Remember, knowledge is power, and the more we know about our bodies and menstrual health, the better equipped we are to navigate that time of the month like the bosses we are. Stay curious, stay informed, and above all, stay fabulous! 🌟✨</span></p>`
    }, {
        title: "ARE YOU ANXIOUS ABOUT",
        image: "/blog-image/cramps.png",
        slug: "beat-period-cramps",
        subtitle: "Beat Period Cramps",
        details: `<p style="text-align: justify;"><strong><span > How to Beat Period Cramps and Feel Fabulous: A Teen&apos;s Guide</span></strong></p>
    <p style="text-align: justify;"><em><span >Almost every girl experiences them at some point&hellip;</span></em></p>
    <p style="text-align: justify;"><span >Hey there, fabulous teens!&nbsp;</span></p>
    <p style="text-align: justify;"><span >Let&apos;s talk about something that&apos;s no stranger to any of us: period cramps. Yep, those pesky little pains that can make us feel like curling up in a ball and binge-watching our favorite shows all day. But fear not! We&apos;ve got some awesome tips to help you beat those cramps and feel absolutely fabulous during that time of the month.</span></p>
    <p style="text-align: justify;"><span >First things first, let&apos;s talk about why those cramps happen in the first place. When your period comes visiting, your uterus decides to do a little workout to shed its lining. Cue the cramps! But don&apos;t worry, you&apos;re not alone. Almost every girl experiences them at some point. So, what can you do to ease the discomfort and keep slaying like the queen you are?</span></p>
    <ol>
        <li><em>
                <ul>
                    <li style="list-style-type:decimal;">
                        <p style="text-align: justify;"><span >Heat it up, baby! Grab a cozy heating pad or a hot water bottle and place it on your lower abdomen. The warmth helps relax those stubborn muscles and eases the cramps. Plus, it feels like getting a warm hug from your favorite teddy bear.</span></p>
                    </li>
                </ul>
            </em></li>
    </ol>
   
    <ol start="2">
        <li><em>
                <ul>
                    <li style="list-style-type:decimal;">
                        <p style="text-align: justify;"><span >Move that body! I know, I know, the last thing you want to do when you&apos;re crampy is exercise. But trust me, a little movement can work wonders. Take a gentle walk, do some yoga stretches, or dance around your room to your favorite tunes. Exercise releases feel-good endorphins that can help alleviate pain and boost your mood.</span></p>
                    </li>
                </ul>
            </em></li>
    </ol>
   
    <ol start="3">
        <li><em>
                <ul>
                    <li style="list-style-type:decimal;">
                        <p style="text-align: justify;"><span >Treat yourself! Whip up a delicious batch of your favorite comfort food or indulge in some yummy dark chocolate (yes, it&apos;s scientifically proven to help with cramps!). Just remember to enjoy those treats in moderation and opt for healthier options whenever possible.</span></p>
                    </li>
                </ul>
            </em></li>
    </ol>
   
    <ol start="4">
        <li><em>
                <ul>
                    <li style="list-style-type:decimal;">
                        <p style="text-align: justify;"><span >Get your zen on. Stress can make those cramps feel even worse, so take some time to relax and unwind. Practice deep breathing exercises, meditate, or simply take a hot bath with soothing essential oils. Your body and mind will thank you for it!</span></p>
                    </li>
                </ul>
            </em></li>
    </ol>
   
    <ol start="5">
        <li><em>
                <ul>
                    <li style="list-style-type:decimal;">
                        <p style="text-align: justify;"><span >Stay hydrated and snack smart. Drinking plenty of water and munching on hydrating fruits and veggies can help reduce bloating and keep your body feeling balanced and energized. Say hello to glowing skin and goodbye to those pesky cramps!</span></p>
                    </li>
                </ul>
            </em></li>
    </ol>
    <p style="text-align: justify;"><span >Remember, it&apos;s totally normal to experience period cramps, but you don&apos;t have to let them hold you back. With a little self-care and a whole lot of self-love, you can beat those cramps and feel absolutely fabulous, inside and out. So go ahead, conquer the world, one cramp at a time! You&apos;ve got this, girl! 💪🌟</span></p>`
    }, {
        title: "ARE YOU ANXIOUS ABOUT",
        image: "/blog-image/diy-period-kits.png",
        slug: "diy-period-kits",
        subtitle: "DIY Period Kits",
        details: `<p style="text-align: justify;"><strong><span > DIY Period Kits: What Every Teen Girl Needs in Her Backpack</span></strong></p>
    <p style="text-align: justify;"><em><span >Every period kit needs a few must-have items to keep you feeling fresh and fabulous throughout the day.</span></em></p>
    <p style="text-align: justify;"><span >Hey there, fabulous teens!&nbsp;</span></p>
    <p style="text-align: justify;"><span >Are you ready to tackle your period like the fierce warrior that you are? Well, get your backpacks ready, because we&apos;re diving into the wonderful world of DIY period kits that every teen girl needs to conquer that time of the month with style and ease.</span></p>
    <p style="text-align: justify;"><span >First things first, let&apos;s talk essentials. Every period kit needs a few must-have items to keep you feeling fresh and fabulous throughout the day. Think of it as your trusty sidekick, ready to save the day whenever Aunt Flo comes knocking!</span></p>
    <ol>
        <li style="list-style-type:decimal;">
            <p><strong><span >Period Products:</span></strong><span >&nbsp;Whether you&apos;re Team Tampon, Team Pad, or Team Menstrual Cup, make sure to pack your preferred period products. Choose what works best for you and don&apos;t be afraid to experiment until you find the perfect fit.</span></p>
        </li>
        <li style="list-style-type:decimal;">
            <p><strong><span >Wet Wipes or Tissues:</span></strong><span >&nbsp;For those quick bathroom breaks on the go, wet wipes or tissues are a lifesaver. They&apos;ll help you feel clean and refreshed, even when you&apos;re out and about.</span></p>
        </li>
        <li style="list-style-type:decimal;">
            <p><strong><span >Pain Relief:&nbsp;</span></strong><span >Say goodbye to pesky period cramps with some pain relief medication like ibuprofen or acetaminophen. Just remember to check with your healthcare provider before taking any medication.</span></p>
        </li>
        <li style="list-style-type:decimal;">
            <p><strong><span >Snacks:</span></strong><span >&nbsp;Because let&apos;s face it, period cravings are no joke! Pack some healthy snacks like nuts, fruits, or granola bars to keep those hunger pangs at bay and your energy levels up.</span></p>
        </li>
        <li style="list-style-type:decimal;">
            <p><strong><span >Spare Underwear:&nbsp;</span></strong><span >Accidents happen, and that&apos;s totally okay! Pack a spare pair of underwear just in case, so you can breeze through the day with confidence and peace of mind.</span></p>
        </li>
    </ol>
    <p style="text-align: justify;"><span >Now, let&apos;s get creative with some fun and funky additions to your period kit that&apos;ll make that time of the month feel like a breeze:</span></p>
    <ol>
        <li style="list-style-type:decimal;">
            <p><strong><span >Cute Pouch:</span></strong><span >&nbsp;Keep all your period essentials organized and stylish with a cute pouch or bag. Opt for bright colors or fun patterns to add a pop of personality to your period kit.</span></p>
        </li>
        <li style="list-style-type:decimal;">
            <p><strong><span >Essential Oil Roller:</span></strong><span >&nbsp;Roll away those period blues with a soothing essential oil roller. Lavender, peppermint, and chamomile are great options for calming your mind and easing those cramps.</span></p>
        </li>
        <li style="list-style-type:decimal;">
            <p><strong><span >Period Playlist:&nbsp;</span></strong><span >Create a special playlist filled with your favorite tunes to boost your mood and keep you feeling empowered during your period. Dance parties in the bathroom, anyone?</span></p>
        </li>
        <li style="list-style-type:decimal;">
            <p><strong><span >Positive Affirmations:&nbsp;</span></strong><span >Write down some uplifting affirmations or quotes to remind yourself just how amazing and resilient you are, even on your toughest days.</span></p>
        </li>
        <li style="list-style-type:decimal;">
            <p><strong><span >Heat Pack:</span></strong><span >&nbsp;Say hello to your new best friend&mdash;heat packs! Whether it&apos;s a reusable one or a disposable hand warmer, heat packs can work wonders for soothing period cramps and keeping you cozy and warm.</span></p>
        </li>
    </ol>
    <p style="text-align: justify;"><span >And there you have it, fabulous teens&mdash;your ultimate guide to DIY period kits! Remember, your period is nothing to be ashamed of. Embrace it, celebrate it, and most importantly, take care of yourself during that time of the month. With your DIY period kit by your side, you&apos;ll be ready to conquer the world, one period at a time! 🌸💪</span></p>`
    }, {
        title: "ARE YOU ANXIOUS ABOUT",
        image: "/blog-image/emotional-stress.png",
        slug: "emotional-stress-during-period",
        subtitle: "Emotional Stress during Period",
        details: `<p><strong><span style="">HOW TO DEAL WITH EMOTIONAL STRESS DURING YOUR PERIOD.</span></strong></p>
   
    <p><em><span style="">Preview texts: Discover five practical tips to help ease emotional stress during your period.</span></em></p>
   
    <p><span style="">It can be very unpredictable to determine how your emotions would play out during your period. It is very common for you to feel depressed, sad, anxious before or during your period, sometimes it might lead to crying out of confusion.</span></p>
   
    <p><span style="">The first thing to understand is that, it is absolutely normal to feel this way, experts believe a drop of these hormones (estrogen and progesterone) that occurs after ovulation could trigger the feelings.</span></p>
   
    <p><span style="">Once you find yourself sad, tired or irritated during your period, the first thing to do is applaud yourself for being a woman. Only extremely strong humans go through a bleeding process every month and are able to come out strong. Take pleasure in identifying yourself as a strong woman!</span></p>
   
    <p><span style="">There are a couple of period practices that can help you ease emotional stress during your period. Here are five tips that are very easy to practice:</span></p>
   
    <p><span style="">1. Spend time with friends</span></p>
    <p><span style="">Social support plays a huge role in reducing emotional stress. Spend time talking to a friend on a call or you can have one come over to visit you. This can relieve you and help you relax. In no time you&apos;re talking about different things that take your mind off your current condition.</span></p>
   
    <p><span style="">2. Rest! Rest!! Rest!!!</span></p>
    <p><span style="">One of the juiciest benefits of being on your period is the justified access to self care you have. Take advantage of resting during this period, don&apos;t get yourself involved in strenuous activities. If you&apos;re too tired and you need help for housekeeping duties, you can book an appointment with a caregiver to help you (there&apos;s a section for that on the MyFairies app). Take plenty of rest during this period, it would help calm you down and get your feelings sorted out. Remember this is the best time to get rest without any feeling of guilt!</span></p>
   
    <p><span style="">3. Herbal tea and supplements</span></p>
    <p><span style="">Adrenaline is the hormone responsible for emotions, one way to ease emotional stress during your period is adding supplements to your routine and drinking herbal tea regularly. Ginseng, black currant and Rhadiola are a few recommendations that aid your adrenal glands with energy production. You can try out other herbal teas and supplements that help you maintain a calm state of mind.</span></p>
   
    <p><span style="">4. Eat healthy foods</span></p>
    <p><span style="">You might be craving something sweet and the first thing that comes to mind is junks. To be on a safer side, eat sweet fruits or dark chocolates which have little sugar and enough antioxidants. Including hormonal supportive substances in your diet during your period would help you relax.</span></p>
   
    <p><span style="">5. Distract yourself from your emotions</span></p>
    <p><span style="">This doesn&apos;t seem like the easiest thing to do but it turns out that it is a very effective way to relax. What do you enjoy doing? What things make you laugh so hard? Get yourself involved in things that would make you forget how you&apos;re feeling. You can watch a skit or a movie filled with funny scenes. This would help you relax and relieve you of any emotional stress you&apos;re going through.</span></p>
   
   
    <p><span style="">Cramps, blood, confused feeling, bad cravings, irritation among others. A combination of all these or one of these can make you sad or stressed emotionally. Take out time to consciously practice the recommended tips to help you feel better.&nbsp;</span></p>
   
    <p><span style="">Never forget, you&apos;re a strong woman!</span></p>`
    }, {
        title: "ARE YOU ANXIOUS ABOUT",
        image: "/blog-image/support.png",
        slug: "how-to-support-your-friends",
        subtitle: "How to Support Your Friends",
        details: `<p style="text-align: justify;"><strong><span > Period Pals: How to Support Your Friends During Their Time of the Month</span></strong></p>
    <p style="text-align: justify;"><em><span >We&apos;re diving into the world of period pals and how you can show some major love and support to your BFFs during their time of the month!</span></em></p>
    <p style="text-align: justify;"><span >Hey there, awesome teens!&nbsp;</span></p>
    <p style="text-align: justify;"><span >Let&apos;s talk about something super important&mdash;being there for your friends when your period decides to make her monthly visit. Yep, we&apos;re diving into the world of period pals and how you can show some major love and support to your BFFs during their time of the month. So grab a cozy spot and let&apos;s get chatting!</span></p>
    <p style="text-align: justify;"><span >First things first, let&apos;s talk about why supporting your friends during their periods is so freaking awesome. I mean, think about it&mdash;periods can be tough! From cramps and mood swings to unexpected leaks (yikes!), it&apos;s like our bodies decide to throw a little party every month. But with a little help from our period pals, we can get through it all with a smile on our faces.</span></p>
    <p style="text-align: justify;"><span >So, how can you be the ultimate period pal to your BFFs? It&apos;s simple, really! Here are a few tips to help you show some major love and support:</span></p>
    <ol>
        <li style="list-style-type:decimal;">
            <p><span >Be Empathetic: First and foremost, listen to your friend and let them know that you understand what they&apos;re going through. Empathy goes a long way, so offer a shoulder to lean on and a listening ear whenever they need it.</span></p>
        </li>
        <li style="list-style-type:decimal;">
            <p><span >Offer Supplies: Be the hero your friend needs and come to the rescue with some period essentials. Whether it&apos;s pads, tampons, or chocolate (because let&apos;s face it, chocolate makes everything better), having a stash of supplies on hand can make a world of difference.</span></p>
        </li>
        <li style="list-style-type:decimal;">
            <p><span >Send Some Love: Who doesn&apos;t love a sweet text or a funny meme when they&apos;re feeling down? Send your friend a little love note or a silly joke to brighten their day and let them know that you&apos;re thinking of them.</span></p>
        </li>
        <li style="list-style-type:decimal;">
            <p><span >Plan Some Self-Care: Treat your friend to a little self-care session to help them relax and unwind. Whether it&apos;s watching their favorite movie, doing some yoga together, or baking some delicious treats, spending quality time with your BFF can help lift their spirits and make them feel pampered.</span></p>
        </li>
        <li style="list-style-type:decimal;">
            <p><span >Normalize Period Talk: Break the taboo and talk openly about periods with your friends. By normalizing period talk, we can create a supportive and inclusive environment where everyone feels comfortable discussing their menstrual health.</span></p>
        </li>
    </ol>
    <p style="text-align: justify;"><span >Remember, being a period pal is all about showing kindness, empathy, and support to your friends when they need it most. So let&apos;s lift each other up, celebrate our bodies, and be there for our BFFs, one period at a time! 🌸💖👯&zwj;♀️</span></p>`
    }
]