import { StyleSheet } from "aphrodite";


export default StyleSheet.create({
    blogCard:{   
         background: '#FFF7F8',
         padding:'100px',
         display:'flex',
         position:'relative',

         '@media(max-width:958px)':{
            flexDirection:'column',
            padding:50
         },
    },
    myflairlogo:{
        position: "absolute",
        top: "18px",
        right: "16px",
        zIndex: "1",
    },
    textA: {
        fontFamily: 'Aristotelica Display Trial',
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "32px",
        lineHeight: "99%",
        color: "#190714",
        padding: "42px 43px 10px 35px",
        margin: "auto",
    },
    textB:{
        fontWeight: "200",
        fontSize: "11px",
        lineHeight: "12px",
        color: "#190714",
        maxWidth: "213px",
        margin: "0 auto 45px 35px",
    },
    container:{
        width:'100%',
        overflowX:'scroll',
        display:'flex',

    },
    line:{
        height:'131px',
        borderRight:'1px solid rgba(148, 148, 148, 0.25)',
        width:'0px',
        '@media(max-width:958px)':{
            borderRight:'none',
            height:'unset',
            borderBottom:'1px solid rgba(148, 148, 148, 0.25)',
            width:'50%',
            paddingTop:'-10px'
         },
    },
    flex:{
        '@media(max-width:958px)':{
            display:'flex'
         },
    },
    head:{
        fontFamily: 'Aristotelica Display Trial',
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "50px",
        lineHeight: "80%",
        color: '#190714',
        width:'191px',
        '@media(max-width:958px)':{
            width: '196px',
           fontSize: "26px",
         },

    },
    grid:{
        position: "relative",
        width: "309px",
        minHeight: "360px",
        background: "linear-gradient(143.49deg, #FFF6F8 57.17%, #FFABBC 143.92%)",
        borderRadius: "15px",
        margin:'10px 20px'
    },
    bg1:{
      background:'linear-gradient(325.43deg, #4FCAF7 -60.92%, #E8F9FF 51.27%)'
    },
    bg2:{
        background:'linear-gradient(330.12deg, rgba(202, 211, 255, 0.76) -24.08%, #F4F6FF 65.63%)'
      },
      bg3:{
        background:'linear-gradient(330.12deg, rgba(255, 255, 255, 0.76) -24.08%, #fff 65.63%)'
      },
    uni:{
        float:'none',
        '@media(max-width:958px)':{
            display:'none'
         },
    }
});