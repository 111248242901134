import React from 'react'
import './Styles.scss'
import Logo  from '../../assets/images/Logo2/Logo2.png';
import BlackAppStore from  '../../assets/images/BlackAppStore.png';
import BlackPlayStore from  '../../assets/images/BlackPlayStore.png';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants';


const DownloadModal = () => {
 
  return (
   <div className="container">
      <div className='modal'>
      <div className="logo">
                <Link to={ROUTES.HOME}>
                    <img src={Logo} alt="myfaires" />
                </Link>
            </div>
            {/* <div className="dismiss">
                &times;
            </div> */}
          <p className="text">
          How do you want to download the app?
          </p>
          <div className="btnCont">
           <Link to="#">
           <img src={BlackPlayStore} alt="" className="btn img1" />
           </Link>
           <Link to="#">
           <img src={BlackAppStore}alt="" className="btn img2" />
           </Link>
          </div>
    </div>
   </div>
  )
}

export default DownloadModal