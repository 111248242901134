import React from 'react'
import './Styles.scss'
import { Link } from 'react-router-dom';
import Clock from '../../assets/images/Clock/Clock.png';
import Like from '../../assets/images/Like/Like.png';
import Flag from '../../assets/images/Flag/Flag.png';
import Logo from '../../assets/images/Logo2/Logo2.png';
import { ROUTES } from '../../constants';

const index = () => {
  return (
    <div className='bias'>
        <div className="section_1">
        <div className='section '>
            <img src={Logo} alt="" />
            <div className='flex'>
              <p className='flex_text'>
              Together we can Break the Bias with MyFairies
              </p>
            </div>
        </div>
        </div>
        <div className="section_2">
          <div className="flex">
            <p className="support_text">
               WE SUPPORT WOMEN
            </p>
            <p className="bias_text">
            Breaking the Bias with MyFairies
            </p>
          </div>
         
        </div>
    </div>
  )
}

export default index