// @flow
import React from "react";

import CardBView from "./CardBView";

export default class CardBController extends React.Component {
 

  render() {
    return (
      <CardBView {...this.props} />
    );
  }
}
