import { StyleSheet } from "aphrodite";


export default StyleSheet.create({
    blogCard:{
        width: "100%",
        background: "rgba(255, 255, 255, 0.2)",
        padding: "45px 0px",
        display: "flex",
        flexWrap: "wrap",
        minHeight:'500px',
        justifyContent: "space-around",
        alignItems: "center",
        position:'relative',
        '@media(max-width:1276px)':{
          flexDirection:'column-reverse',
          paddingBottom:'100px',
          justifyContent: 'center !important',
         }            

    },
    abs:{
        '@media(max-width:1276px)':{
            boxSizing: 'border-box !important',
            width:'80%',
            // margin:'10px auto',
            transform: 'translateX(-50%)',
            left: '50%',
           position:'absolute',
           bottom:'0',
           display:'flex',
           flexDirection:'column',
           alignContent:'space-between',
           justifyContent: 'center',
           alignItems: 'center',
        //    background: 'blue',
           '@media(max-width: 475px)':{
            width: '90%'
           }  
        }  
    },
    head:{
        fontStyle: "normal",
        fontWeight: "600",
        fontFamily:"Aristotelica Display Trial",
        fontSize: "60px",
        lineHeight: "80%",
        color: "#190714",
        maxWidth: "601px",
        margin: "auto",
        "@media (max-width: 1276px)": {
            maxWidth: "60% !important",
            lineHeight: "90%",
            margin: "auto",
            textAlign: 'center'
        },
        "@media (max-width: 803px)": {
            maxWidth: "70% !important",
            lineHeight: "90%",
            margin: "auto",
            textAlign: 'center'
        },
        "@media (max-width: 688px)": {
            maxWidth: "80% !important",
        },
        "@media (max-width: 602px)": {
            maxWidth: "90% !important",
            fontSize: '54px'
        },
        "@media (max-width: 482px)": {
            maxWidth: "96% !important",
            fontSize: '45px'
        }
    },
    textA: {
        fontWeight: "400",
        fontSize: "20px",
        lineHeight: "24px",
        color: "#190714",
        maxWidth: "473px",
        margin: "14px 0 33px 0",
        "@media (max-width: 1276px)": {
            maxWidth: "70% !important",
            margin: " 14px auto 33px auto",
            textAlign: 'center'
        },
        "@media (max-width: 602px)": {
            maxWidth: "96% !important",
            fontSize: "18px",
        },
        
        
    },
    box:{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gridGap: "20px",
        maxWidth: "473px",
        marginBottom: "50px",
        textAlign: 'center',
        '@media (max-width: 1276px)': {
            width: '100%',
            maxWidth: 'unset !important',
            // columnGap: '300px !important',
            justifyContent: 'space-between !important',
            alignItems: 'space-between',
        }  
    },
    con:{
        minWidth: "150px",
        display: "flex",
        alignItems: "center",
        // background: 'blue',
        '@media (max-width: 1276px)': {
            ':nth-of-type(even)': {
                justifyContent: 'end',
            }
        },
        '@media (max-width: 475px)': {
            // width: '90%',
            marginLeft: '8%',
            // background: 'red'
        }
    },
    span:{
        fontWeight: "600",
        fontSize: "20px",
        lineHeight: "24px",
        color: "#190714",
        marginLeft: "10px",
        letterSpacing: "1px",
        '@media (max-width: 1276px)': {
            width: '150px',
        },
        '@media (max-width: 475px)': {
            // width: '90%',
            // background: 'red'
        }
    },
    btnCon:{
        display: "flex",
        // maxWidth: "460px",
        // margin: 'auto',
        '@media (max-width:520px)': {
            transform: 'scale(0.9)',
            '@media (max-width:425px)': {
                transform: 'scale(0.7)',
            }
        }
    },
    btn:{
        display: "flex",
        alignItems: "center",
        margin:'0 10px 0 0',
        background: "#FFABBC",
        borderRadius: "5px",
        fontWeight: 600,
        fontFamily: 'Aristotelica Display Trial',
        fontSize: "24px",
        lineHeight: "26px",
        color: "#FFFFFF",
        border: "none",
        outline: "none",
        marginRight:'40px',
        maxWidth: "220px",
        marginBottom:'20px',
        padding: "10px 32px 10px 33px",
        width: "220px",
        // marginRight:'10%',
        
    },
    days:{
        '@media(max-width:720px)':{
           transform:'scale(0.8)',
           marginLeft:'-80px'
        },
        '@media (max-width: 1276px)': {
            //    background: 'red',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    },
    Clock: {
        "@media (max-width: 1276px)": {
                // background: 'blue',
            paddingBottom: '15%',
            width: '100%',
            marginLeft: '-10% !important',
        },
        "@media (max-width: 721px)": {
            marginLeft: '0% !important',
        },
        "@media (max-width: 688px)": {
            paddingBottom: '10%',
            marginTop: '-16% !important'
        },
        "@media (max-width: 602px)": {
            paddingBottom: '8%',
            marginTop: '-20% !important'
        },
        "@media (max-width: 560px)": {
            marginLeft: '10% !important',
        }
        
    }
});